import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { MapService } from "./map.service";
import { Map } from "./map";

@Injectable({
    providedIn: "root"
})
export class MapListResolverService implements Resolve<Map[]> {
    constructor(private ms: MapService) {}

    resolve(): Observable<Map[]> | Observable<never> {
        return new Observable((observe: Subscriber<Map[]>) => {
            this.ms.refreshMaps().subscribe((maps: Map[]) => {
                observe.next(maps);
                observe.complete();
            });
        });
    }
}
