import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { DataMarker } from "../../map";
import { Zec } from "../../../zecs/zecs/zec";

@Component({
    selector: "app-zec-content",
    templateUrl: "./zec-content.component.html"
})
export class ZecContentComponent implements OnChanges {
    @Input() zec: Zec;
    @Input() canEdit: boolean;
    @Input() dataMarker: DataMarker;
    pwVisible = false;

    dataMarkerLatLng() {
        if (this.dataMarker) {
            const latlng = this.dataMarker.getLatLng();
            return { lat: latlng.lat, lng: latlng.lng };
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.zec) {
            if (changes.zec.previousValue && changes.zec.currentValue) {
                if (changes.zec.previousValue.id !== changes.zec.currentValue.id) {
                    this.pwVisible = false;
                }
            }
        }
    }

    get Math() {
        return Math;
    }
}
