<div class="form-group row">
    <div class="col">
        <zx-source [model]="source" [showStatusIcon]="true" [showStatusText]="false" [showLink]="true" [showTag]="true"></zx-source>
    </div>
</div>
<div class="form-group row" *ngIf="!source.medialive_channel_id">
    <h5 class="col-12" translate>AWS_MEDIACONNECT_FLOW</h5>
    <div class="col-12">
        <span *ngIf="!source.mediaconnect_flow_id">-</span>
        <zx-mediaconnect-flow
            [model]="source.mediaconnectFlow"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="false"
            *ngIf="source.mediaconnect_flow_id"
        ></zx-mediaconnect-flow>
    </div>
</div>
<div class="form-group row" *ngIf="source.medialive_channel_id">
    <h5 class="col-12" translate>AWS_MEDIALIVE_CHANNEL</h5>
    <div class="col-12">
        <zx-medialive-channel
            [model]="source.mediaLiveChannel"
            [showStatusIcon]="false"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="false"
        ></zx-medialive-channel>
    </div>
</div>
<div class="form-group row" *ngIf="source.resourceTags && source.resourceTags.length">
    <h5 class="col-12" translate>TAGS</h5>
    <div class="col-12">
        <span *ngFor="let tag of source.resourceTags; index as i"><span *ngIf="i > 0">, </span>{{ tag.name }}</span>
    </div>
</div>

<div class="form-group row" *ngIf="source.alertingProfile">
    <h5 class="col-12" translate>ALERTING_PROFILE</h5>
    <div class="col-12">
        <span>{{ source.alertingProfile.name }}</span>
    </div>
</div>

<div class="form-group row">
    <h5 class="col-12" translate>TYPE</h5>
    <div class="col-12">
        <span>{{ "TO_MEDIACONNECT" | translate }}</span>
    </div>
</div>

<div class="form-group row" *ngIf="source.feeder_id || source.broadcaster_id || source.input_id">
    <h5 class="col-12" translate>INPUT</h5>
    <div class="col-12">
        <zx-feeder *ngIf="source.feeder_id" [model]="source.feeder" [showTag]="true" [showLink]="true" [showStatusIcon]="false"></zx-feeder>
        <zx-broadcaster
            *ngIf="source.broadcaster_id"
            [model]="source.broadcaster"
            [showTag]="true"
            [showLink]="true"
            [showCluster]="false"
            [showStatusIcon]="false"
        ></zx-broadcaster>
        <span *ngIf="source.input_id"><span *ngIf="source.feeder_id || source.broadcaster_id">&nbsp;/&nbsp;</span>{{ source.input_id }}</span>
    </div>
</div>

<div class="form-group row" *ngIf="source.feeder_id">
    <h5 class="col-12" translate>MAX_BITRATE</h5>
    <div class="col-12">
        <span>{{ source.max_bitrate | bitrate }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="source.feeder_id || source.broadcaster_id">
    <h5 class="col-12">{{ "LATENCY" | translate }} [ms]</h5>
    <div class="col-12">
        <span>{{ source.latency }}</span>
    </div>
</div>
<!-- Location -->
<app-details-location [location]="source.location"></app-details-location>
<div
    class="form-group row mt-2"
    *ngIf="
        !source.location ||
        !source.location?.address ||
        source.location?.address.latitude !== dataMarkerLatLng().lat ||
        source.location?.address.longitude !== dataMarkerLatLng().lng
    "
>
    <h5 class="col-12" translate>LAT/LNG</h5>
    <div class="col-12">
        <span title="{{ 'LAT' | translate }}: {{ dataMarkerLatLng().lat }}, {{ 'LNG' | translate }}: {{ dataMarkerLatLng().lng }}"
            >{{ "LAT" | translate }}: {{ dataMarkerLatLng().lat }}, {{ "LNG" | translate }}: {{ dataMarkerLatLng().lng }}</span
        >
    </div>
</div>
