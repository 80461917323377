import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { LeafletDrawModule } from "@asymmetrik/ngx-leaflet-draw";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Routes
import { MapsRouting } from "./maps.routing";

// Components
import { MapsComponent } from "./maps.component";
import { MapListComponent } from "./map-list/map-list.component";
import { MapFormComponent } from "./map-form/map-form.component";
import { MapComponent } from "./map/map.component";
//
import { FeederContentComponent } from "./map/feeder-content/feeder-content.component";
import { BroadcasterContentComponent } from "./map/broadcaster-content/broadcaster-content.component";
import { ReceiverContentComponent } from "./map/receiver-content/receiver-content.component";
import { ZecContentComponent } from "./map/zec-content/zec-content.component";
import { SourceContentComponent } from "./map/source-content/source-content.component";
import { TargetContentComponent } from "./map/target-content/target-content.component";
import { McSourceContentComponent } from "./map/mc-source-content/mc-source-content.component";
import { MapLegendComponent } from "./map/map-legend/map-legend.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        MapsRouting,
        LeafletModule,
        LeafletDrawModule
    ],
    declarations: [
        MapsComponent,
        MapListComponent,
        MapFormComponent,
        MapComponent,
        FeederContentComponent,
        BroadcasterContentComponent,
        ReceiverContentComponent,
        ZecContentComponent,
        SourceContentComponent,
        TargetContentComponent,
        McSourceContentComponent,
        MapLegendComponent
    ]
})
export class MapModule {}
