import { Component, Input } from "@angular/core";
import { DataMarker } from "../../map";
import { MediaConnectSource } from "../../../../models/shared";

@Component({
    selector: "app-mc-source-content",
    templateUrl: "./mc-source-content.component.html"
})
export class McSourceContentComponent {
    @Input() source: MediaConnectSource;
    @Input() canEdit: boolean;
    @Input() dataMarker: DataMarker;

    dataMarkerLatLng() {
        if (this.dataMarker) {
            const latlng = this.dataMarker.getLatLng();
            return { lat: latlng.lat, lng: latlng.lng };
        }
    }
}
