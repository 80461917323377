/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Component, OnInit, NgZone, OnDestroy, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, interval, Subject } from "rxjs";
import { take } from "rxjs/operators";
// Pipes
import { PercentPipe } from "@angular/common";
import { StatusClassPipe } from "../../../pipes/status-class.pipe";
// Constants
import { Constants } from "../../../constants/constants";
// Services
import { MapService } from "../map.service";
import { UsersService } from "../../account-management/users/users.service";
import { SharedService } from "../../../services/shared.service";
import { SourcesService } from "../../sources/sources.service";
import { MediaConnectSourcesService } from "../../sources/mediaconnect-sources.service";
import { ChannelsService } from "../../channels/channels.service";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { TargetsService } from "../../targets/targets.service";
// Models
import * as L from "leaflet";
import {
    NominatimResponse,
    MapPoint,
    DataMarker,
    Map,
    LayerGroupData,
    MarkerData,
    LayerGroupObject,
    LayerInfo
} from "../map";
import { AntPath } from "leaflet-ant-path";
import { ParentClusterGroup as GoodParentClusterGroup } from "../map2";
import { ChildClusterGroup as GoodChildClusterGroup } from "../map3";
import "leaflet.markercluster";
import "leaflet.featuregroup.subgroup";
import "leaflet.markercluster.freezable";
//
import * as _ from "lodash";
//
//
import {
    Broadcaster,
    Source,
    Tag,
    MediaConnectSource,
    SomeZixiObject,
    MediaConnectFlow,
    ActiveBroadcaster,
    UserPermissions
} from "../../../models/shared";
import {
    AdaptiveChannel,
    AnyTarget,
    DeliveryChannel,
    UdpRtpTarget,
    NdiTarget,
    RistTarget,
    SrtTarget,
    RtmpPushTarget,
    ZixiPullTarget,
    ZixiPushTarget,
    PublishingTarget
} from "../../channels/channel";
import { Feeder, Zec } from "../../zecs/zecs/zec";
import { Receiver } from "../../zecs/zecs/zec";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
//
import { ModalService } from "../../../components/shared/modals/modal.service";
import { ExportExcelService } from "src/app/services/export-excel.service";
import { StatusTextPipe } from "src/app/pipes/status-text.pipe";
import { ZecsService } from "../../zecs/zecs.service";
// eslint-disable-next-line
declare let $: any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ParentClusterGroup = any | GoodParentClusterGroup;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ChildClusterGroup = any | GoodChildClusterGroup;

@Component({
    selector: "app-map",
    templateUrl: "./map.component.html",
    styleUrls: ["./map.component.scss"],
    providers: [PercentPipe, StatusClassPipe]
})
export class MapComponent implements OnInit, OnDestroy {
    mapDefinition: Map = null;
    mapID: number;
    // @ts-ignore
    map: L.DrawMap = null;
    mapPoint: MapPoint;
    count = 0;
    constants = Constants;
    //
    currentVisibleLayers: string[] = [];
    savedVisibleLayers: string[] = [];
    savedBaseMap: string;
    options: {
        layers?: L.TileLayer[];
        zoom: number;
        zoomControl: boolean;
        zoomSnap: number;
        center: L.LatLng;
        preferCanvas: boolean;
    };
    layersControl: {
        baseLayers: {
            Street: L.TileLayer;
            Topographic: L.TileLayer;
            Dark: L.TileLayer;
            Light: L.TileLayer;
        };
        overlays: Record<string, ChildClusterGroup>;
    };
    parentGroup: ParentClusterGroup = null;
    clustered = true;
    hasCluster = false;
    unsavedChanges = false;
    //
    bounds: L.LatLngBounds = null;
    polylines: L.Polyline[] = [];
    currentPolylines: L.Polyline[] = [];
    movingPolylines: { polyline: L.Polyline; i: number }[] = [];
    movingMarkers: L.Layer[] = [];
    pathId: number;
    selectedMapObject: DataMarker = null;
    selectedNewObject: DataMarker = null;
    movingMarkerByAddress = false;
    objectsList: LayerGroupObject[] = [];
    unplacedObjects: LayerGroupObject[] = [];
    locationPlacedObjects: LayerGroupObject[] = [];
    tagsList: string[] = [];
    //
    editMap = false;
    loadingMap = true;
    refreshingMap = false;
    savingMap = false;
    isExpanded = false;
    healthMode = 0;
    showTopBar = true;
    //
    showResults = false;
    searchingAddress = false;
    searchAddressResults: NominatimResponse[];
    searchAddressVal: string = null;
    //
    showDetails = false;
    loadingDetails = false;
    showNewObjects = false;
    //
    searchMarkerVal: string = null;
    markerResults = [];
    tagResults = [];
    //
    isAdmin: boolean;
    userPermissions: UserPermissions;
    //
    resourceTags: Tag[];
    currentFeeder: Feeder;
    currentReceiver: Receiver;
    currentZec: Zec;
    currentSource: Source;
    currentMediaConnectSource: MediaConnectSource;
    currentBroadcaster: Broadcaster;
    currentTarget: AnyTarget;
    //
    groupNames: string[] = [];
    baseLat: number;
    baseLng: number;
    //
    private mapsSubscription: Subscription;
    private mapRefreshSubscription: Subscription;

    private openStreetMapLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: Constants.MAX_ZOOM,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });

    private stadiaAlidadeSmoothDarkLayer = L.tileLayer(
        "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png",
        {
            maxZoom: Constants.MAX_ZOOM,
            attribution:
                '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a>, &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors'
        }
    );

    private stadiaAlidadeSmooth = L.tileLayer("https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png", {
        maxZoom: Constants.MAX_ZOOM,
        attribution:
            '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
    });

    private esriWorldTopoMap = L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
        {
            maxZoom: Constants.MAX_ZOOM,
            attribution:
                "Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community"
        }
    );

    private clusterGroupOptions = {
        // spiderfyOnMaxZoom: true,
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: false,
        animate: false,
        animateAddingMarkers: false,
        maxClusterRadius: 80,
        spiderLegPolylineOptions: { weight: 1, color: "#e0e0e0", opacity: 0.5 },
        iconCreateFunction: cluster => {
            return this.createClusterIcon(cluster);
        }
        // TODO: markercluster is behind leaflet release, so this does not work for now
        /*spiderfyShapePositions: (count, centerPt) => {
            // eslint-disable-next-line no-console
            console.log(count, centerPt);
            const distanceFromCenter = 35;
            const markerDistance = 45;
            const lineLength = markerDistance * (count - 1);
            const lineStart = centerPt.y - lineLength / 2;
            const res = [];
            let i;

            res.length = count;

            for (i = count - 1; i >= 0; i--) {
                res[i] = new L.Point(centerPt.x + distanceFromCenter, lineStart + markerDistance * i);
            }

            return res;
        }*/
    };

    // @HostListener allows us to also guard against browser refresh, close, etc.
    @HostListener("window:beforeunload")
    browserDialog() {
        if (this.unsavedChanges) {
            return false;
        } else return true;
    }

    canDeactivate() {
        if (this.editMap && this.unsavedChanges) {
            const subject = new Subject<boolean>();
            const modal = this.modalService.confirmNavigation();
            modal.componentInstance.subject = subject;
            return modal.componentInstance.subject.asObservable();
        } else return true;
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private zone: NgZone,
        private mapService: MapService,
        private statusClassPipe: StatusClassPipe,
        private sharedService: SharedService,
        private userService: UsersService,
        private channelsService: ChannelsService,
        private zecsService: ZecsService,
        private sourcesService: SourcesService,
        private mediaConnectSourcesService: MediaConnectSourcesService,
        private broadcastersService: BroadcastersService,
        private targetsService: TargetsService,
        private translate: TranslateService,
        private titleService: TitleService,
        private modalService: ModalService,
        private excelService: ExportExcelService,
        private stp: StatusTextPipe
    ) {
        this.route.paramMap.subscribe(async params => {
            this.mapDefinition = null;
            // Prevent switch to another map via URL
            if (this.mapID) {
                this.router.navigate([Constants.urls.maps]);
            }
            // Get ID
            this.mapID = parseInt(params.get("id"), 10);
            // Get Map from cache
            let mapDef;
            if (this.mapID) mapDef = this.mapService.getCachedMap(this.mapID);
            // Loaded Details?
            if (!mapDef) {
                this.mapService.refreshMap(this.mapID, true);
            }
        });

        // Debounce search address
        this.addressLookup = _.debounce(this.addressLookup, 500);
    }

    createClusterIcon(cluster) {
        const list: DataMarker[] = cluster.getAllChildMarkers();

        // Remove duplicate markers
        const markers: DataMarker[] = list.filter(
            (elem, index, self) =>
                self.findIndex(t => {
                    return (
                        t.data.id === elem.data.id &&
                        t.data.type === elem.data.type &&
                        t.data.subtype === elem.data.subtype
                    );
                }) === index
        );

        // Get Marker Statuses
        let good = 0;
        let bad = 0;
        let disabled = 0;
        let warning = 0;
        for (const marker of markers) {
            // Health Score
            if (this.healthMode && marker.data?.healthScore && marker.data?.healthScore != null) {
                const score = marker.data?.healthScore;
                const status =
                    score === null
                        ? "none"
                        : score <= Constants.healthScoreThresholds.error
                        ? "bad"
                        : score > Constants.healthScoreThresholds.error && score <= Constants.healthScoreThresholds.good
                        ? "warning"
                        : score > Constants.healthScoreThresholds.good
                        ? "good"
                        : "none";

                if (status === "good") good++;
                else if (status === "bad") bad++;
                else if (status === "warning") warning++;
                else disabled++;
                // Status
            } else {
                const icon: L.DivIcon = marker.getIcon();
                const c = icon.options.className;
                const split = c.split("-");
                const status = split[1];
                if (status === "good") good++;
                else if (status === "bad") bad++;
                else if (status === "warning") warning++;
                else disabled++;
            }
        }

        // Icon Class
        let className;
        if (markers.length <= 5) className = "marker-cluster-small";
        else if (markers.length > 5 && markers.length <= 25) className = "marker-cluster-medium";
        else if (markers.length > 25 && markers.length <= 100) className = "marker-cluster-large";
        else if (markers.length > 100 && markers.length <= 250) className = "marker-cluster-xlarge";
        else className = "marker-cluster-xxlarge";

        // Icon Content
        let html = "";
        if (good > 0) {
            html += "<span class='status-good'>" + good + "</span>";
        }
        if (warning > 0) {
            if (good > 0) html += "/";
            html += "<span class='status-warning'>" + warning + "</span>";
        }
        if (bad > 0) {
            if (good > 0 || warning > 0) html += "/";
            html += "<span class='status-bad'>" + bad + "</span>";
        }
        if (disabled > 0) {
            if (good > 0 || warning > 0 || bad > 0) html += "/";
            html += "<span class='status-disabled'>" + disabled + "</span>";
        }

        // Set Icon
        if (markers.length > 1) {
            return L.divIcon({
                html: "<div><span>" + html + "</span></div>",
                className: "marker-cluster " + className
            });
        } else {
            // If only one marker is in cluster make cluster icon mimic marker icon
            const icon: L.DivIcon = markers[0].getIcon();
            className = icon.options.className;
            className = className + " fakeMarker";
            return L.divIcon({
                html: icon.options.html,
                className
            });
        }
    }

    canEdit(object: SomeZixiObject) {
        return this.sharedService.canEditZixiObject(object, this.resourceTags, this.userPermissions);
    }

    back() {
        this.router.navigate([Constants.urls.maps]);
    }

    toggleExpand() {
        this.isExpanded = !this.isExpanded;
        // Needed to force recalulation of map size
        window.setTimeout(() => {
            this.map.invalidateSize();
        }, 0);
    }

    toggleTopBar() {
        this.showTopBar = !this.showTopBar;
        // Needed to force recalulation of map size
        window.setTimeout(() => {
            this.map.invalidateSize();
        }, 0);
    }

    ngOnInit() {
        // Init
        this.init();

        // isAdmin
        this.userService
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                this.isAdmin = !!user.is_admin;
            });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        // resourceTags
        this.sharedService
            .getResourceTagsByType("maps")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.resourceTags = tags;
            });

        // Maps Subscription
        this.mapsSubscription = this.mapService.maps.subscribe(maps => {
            this.mapDefinition = maps.find((m: Map) => m.id === this.mapID);
            // Set Title
            this.titleService.setTitle(this.translate.instant("MAP") + " - " + this.mapDefinition.name);
            //
            if (this.mapDefinition && this.map) this.prepMap(this.mapDefinition);
        });

        // Start Auto Refresh
        this.startAutoRefresh();
    }

    ngOnDestroy() {
        this.mapsSubscription.unsubscribe();
        this.stopAutoRefresh();
    }

    init() {
        this.options = this.getOptions();
        this.layersControl = this.getLayerControls();
    }

    getOptions() {
        this.options = {
            zoom: 14,
            zoomControl: false,
            zoomSnap: 0.1,
            center: L.latLng({ lat: 0, lng: -30 }),
            preferCanvas: false
        };
        return this.options;
    }

    getLayerControls() {
        this.layersControl = {
            baseLayers: {
                Street: this.openStreetMapLayer,
                Topographic: this.esriWorldTopoMap,
                Dark: this.stadiaAlidadeSmoothDarkLayer,
                Light: this.stadiaAlidadeSmooth
            },
            overlays: {}
        };
        return this.layersControl;
    }

    startAutoRefresh() {
        this.mapRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopAutoRefresh() {
        this.mapRefreshSubscription.unsubscribe();
    }

    gotoEditMapForm(): void {
        this.router.navigate([Constants.urls.maps, this.mapDefinition.id, "edit"]);
    }

    gotoCloneMapForm(): void {
        this.router.navigate([Constants.urls.maps, this.mapDefinition.id, "clone"]);
    }

    unclusterMarkers() {
        this.clustered = false;
        this.hasCluster = false;
        this.parentGroup.disableClustering();
    }

    clusterMarkers() {
        this.clustered = true;
        this.hasCluster = null;
        this.parentGroup.enableClustering();
    }

    @HostListener("document:keydown", ["$event"])
    private ctrlDown(event: KeyboardEvent) {
        if (event.key === "Control" && !this.editMap) {
            this.enableDraggableMarkers();
        }
    }

    @HostListener("document:keyup", ["$event"])
    private ctrlUp(event: KeyboardEvent) {
        if (event.key === "Control" && !this.editMap) {
            this.saveMap();
        }
    }

    getSavedVisibleLayers(mapDef: Map): string[] {
        const savedVisibleLayerGroups: string[] = [];
        //
        for (const group of mapDef.config?.leafletData) {
            if (group.visible) {
                savedVisibleLayerGroups.push(group.layerInfo.name);
            }
        }
        //
        return savedVisibleLayerGroups;
    }

    getVisibleLayers(): string[] {
        const visibleLayerGroups: string[] = [];
        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            const layers = ng.getLayers();
            if (layers.length > 0) {
                if (this.map.hasLayer(ng)) {
                    visibleLayerGroups.push(ng.data.name);
                }
            }
        }
        return visibleLayerGroups;
    }

    getVisibleLayersWithUnplacedObjects() {
        const unplacedObjectsArray: string[] = [];
        for (const key of Object.keys(this.unplacedObjects)) {
            const lg: LayerGroupObject = this.unplacedObjects[key];
            unplacedObjectsArray.push(lg.name);
        }
        //
        if (unplacedObjectsArray.length > 0 && this.currentVisibleLayers.length > 0) {
            const result = unplacedObjectsArray.filter(o => {
                return this.currentVisibleLayers.indexOf(o) > -1;
            });
            return result;
        } else {
            return null;
        }
    }

    checkIfClustersExist() {
        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            const layers = ng.getLayers();
            for (const layer of layers) {
                if (layer instanceof DataMarker) {
                    if (!this.map.hasLayer(layer)) {
                        if (this.currentVisibleLayers.includes(layer.data.layerInfo.name)) {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            const clusterGroup: any = this.parentGroup.getVisibleParent(layer);
                            if (clusterGroup) {
                                const s = clusterGroup._icon.className;
                                // Check if cluster icon is actually a fake marker (mimics a marker)
                                if (!s.includes("fakeMarker")) {
                                    this.hasCluster = true;
                                    return;
                                }
                            }
                        }
                    }
                }
            }
        }
        this.hasCluster = false;
    }

    async prepMap(mapDef: Map) {
        // Clear object list
        this.objectsList = [];
        // Cleat tag list
        this.tagsList = [];
        // Clear path list
        this.currentPolylines = [];
        // Clear polyline id counter
        this.pathId = 0;
        // Get previous visible layer groups
        this.savedVisibleLayers = Object.assign([], this.getSavedVisibleLayers(mapDef));
        // Get previous layer group data
        const prevMapData = [];
        if (this.layersControl.overlays) {
            for (const key of Object.keys(this.layersControl.overlays)) {
                const ng: ChildClusterGroup = this.layersControl.overlays[key];
                const ngl = ng.getLayers();
                const layers = [];
                ngl.forEach((layer: L.Layer) => {
                    if (layer instanceof DataMarker) {
                        const markerData: MarkerData = {
                            marker: true,
                            id: layer.data.id,
                            type: layer.data.type || null,
                            subtype: layer.data.subtype || null,
                            latlng: layer.getLatLng(),
                            layerInfo: {
                                name: layer.data.layerInfo.name,
                                channel: layer.data.layerInfo.channel,
                                channelID: layer.data.layerInfo.channelID,
                                channelType: layer.data.layerInfo.channelType,
                                layerID: layer.data.layerInfo.layerID
                            }
                        };
                        layers.push(markerData);
                    } else {
                        const data = layer["options"].data;
                        const path = {
                            obj1: { id: data.obj1.id, type: data.obj1.type, subtype: data.obj1.subtype || null },
                            obj2: { id: data.obj2.id, type: data.obj2.type, subtype: data.obj2.subtype || null },
                            layerInfo: data.layerInfo
                        };
                        layers.push(path);
                    }
                });
                prevMapData.push({
                    layers,
                    name: ng.data.name,
                    channel: ng.data.channel,
                    channelID: ng.data.channelID,
                    channelType: ng.data.channelType,
                    layerID: ng.data.layerID
                });
            }
        }

        // Animation
        this.loadingMap = true;

        // Set baseLayer
        if (mapDef.baseMap && !this.savedBaseMap) {
            this.layersControl.baseLayers[
                mapDef.baseMap === "street"
                    ? "Street"
                    : mapDef.baseMap === "dark"
                    ? "Dark"
                    : mapDef.baseMap === "light"
                    ? "Light"
                    : "Topographic"
            ].addTo(this.map);
        } else {
            this.layersControl.baseLayers[this.savedBaseMap].addTo(this.map);
        }

        // Set center
        if (mapDef.region) {
            this.options.center = this.constants.regionCenter[mapDef.region];
        }

        // Set parent cluster group
        if (!this.parentGroup) {
            this.parentGroup = new GoodParentClusterGroup(this.clusterGroupOptions);
            // Add cluster click event
            this.parentGroup.on("clusterclick", c => {
                this.zone.run(() => {
                    // Get bounds of cluster and zoom map
                    const bounds = c.propagatedFrom.getBounds();
                    this.map.fitBounds(bounds, { padding: L.point(32, 48) });
                    // Get child markers
                    const list: DataMarker[] = c.propagatedFrom.getAllChildMarkers();
                    // Remove duplicate markers
                    const markers: DataMarker[] = list.filter(
                        (elem, index, self) =>
                            self.findIndex(t => {
                                return (
                                    t.data.id === elem.data.id &&
                                    t.data.type === elem.data.type &&
                                    t.data.subtype === elem.data.subtype
                                );
                            }) === index
                    );
                    // If only one marker open that markers object details panel
                    if (markers.length === 1) this.objectClicked(null, markers[0]);
                    else {
                        // Check if makers all have same lat/lng
                        const latlngs: L.LatLng[] = [];
                        markers.forEach(marker => {
                            const latlng: L.LatLng = marker.getLatLng();
                            latlngs.push(latlng);
                        });
                        const allEqual = latlngs.every(l => l.lat === latlngs[0].lat && l.lng === latlngs[0].lng);
                        // Create cluster popup
                        if (allEqual) {
                            // Popup content
                            let popUpContent = "<ul>";
                            markers.forEach(marker => {
                                popUpContent +=
                                    "<li><a class='popup-marker-link' data-id='" +
                                    marker.data.id +
                                    "' data-type='" +
                                    marker.data.type +
                                    "' data-subtype='" +
                                    marker.data.subtype +
                                    "'>" +
                                    marker["_tooltip"]._content +
                                    "</a></li>";
                            });
                            popUpContent += "</ul>";
                            // Open popup on map
                            const popup = L.popup().setLatLng(latlngs[0]).setContent(popUpContent);
                            this.map.openPopup(popup);
                            // Add event listenter to popup to get click events
                            const links = popup.getElement().querySelectorAll(".popup-marker-link");
                            links.forEach((link: HTMLElement) => {
                                link.addEventListener("click", () => {
                                    // Find marker on click
                                    const id = link.dataset.id;
                                    const type = link.dataset.type;
                                    const subtype = link.dataset.subtype;
                                    let marker;
                                    if (subtype === "null") {
                                        marker = this.findMapMarkerAnyLayer(parseInt(id, 10), type, null);
                                    } else {
                                        marker = this.findMapMarkerAnyLayer(parseInt(id, 10), type, subtype);
                                    }
                                    // Open object details panel
                                    if (marker) this.objectClicked(null, marker);
                                });
                            });
                        }
                    }
                });
            });

            // Add cluster spiderfy and unspiderfy events
            /*this.parentGroup.on("spiderfied unspiderfied", () => {
                this.zone.run(() => {
                // eslint-disable-next-line no-console
                    console.log("spiderfied unspiderfied");
                });
            });*/

            // Add map animation end event
            this.parentGroup.on("animationend", () => {
                this.zone.run(() => {
                    // Enable/Disable draggable markers
                    if (this.editMap) this.enableDraggableMarkers();
                    else this.disableDraggableMarkers();
                    // Check if any marker is in a cluster
                    this.checkIfClustersExist();
                });
            });
            //
            this.parentGroup.addTo(this.map);
        }

        // MediaConnect Flows
        if (mapDef.config.mediaconnect_flows) {
            for (const c of mapDef.config.mediaconnect_flows) {
                const channelData = await this.channelsService.getMediaConnectFlow(c);
                if (channelData) await this.leafletChannelProcess(channelData, channelData.name, "mediaconnect");
            }
        }
        // Adaptive Channels
        if (mapDef.config.adaptive_channels) {
            for (const c of mapDef.config.adaptive_channels) {
                const channelData = await this.channelsService.getAdaptiveChannel(c);
                if (channelData) await this.leafletChannelProcess(channelData, channelData.name, "adaptive");
            }
        }
        // Delivery Channels
        if (mapDef.config.delivery_channels) {
            for (const c of mapDef.config.delivery_channels) {
                const channelData = await this.channelsService.getDeliveryChannel(c);
                if (channelData) await this.leafletChannelProcess(channelData, channelData.name, "delivery");
            }
        }
        // Groups
        if (mapDef.config.groups) {
            for (const group of mapDef.config.groups) {
                if (group.feeders && group.feeders.length > 0) {
                    for (const id of group.feeders) {
                        await this.zecsService.refreshZec(id, "FEEDER").toPromise();
                    }
                }
                if (group.receivers && group.receivers.length > 0) {
                    for (const id of group.receivers) {
                        await this.zecsService.refreshZec(id, "RECEIVER").toPromise();
                    }
                }
                if (group.zecs && group.zecs.length > 0) {
                    for (const id of group.zecs) {
                        await this.zecsService.refreshZec(id, "ZEC").toPromise();
                    }
                }
                if (group.broadcasters && group.broadcasters.length > 0) {
                    for (const id of group.broadcasters) {
                        await this.broadcastersService.refreshBroadcaster(id).toPromise();
                    }
                }
                if (group.sources && group.sources.length) {
                    for (const id of group.sources) {
                        await this.sourcesService.refreshSource(id).toPromise();
                    }
                }
                if (group.mediaconnect_sources && group.mediaconnect_sources.length) {
                    for (const id of group.mediaconnect_sources) {
                        await this.mediaConnectSourcesService.refreshMediaConnectSource(id).toPromise();
                    }
                }
                if (group.targets) {
                    for (const typeID of group.targets) {
                        const arr = typeID.split("-");
                        const type = arr[0];
                        const id = parseInt(arr[1], 10);
                        await this.targetsService
                            .refreshTarget(this.targetsService.getTargetApiType(null, type), id)
                            .toPromise();
                    }
                }
            }

            // Process Data
            this.leafletGroupsProcess(mapDef);
        }
        // Reset generatedLatLng
        this.resetLatLng();

        // Remove paths and markers which no longer exist in map definition
        if (prevMapData) {
            prevMapData.forEach(lg => {
                if (lg.layers) {
                    lg.layers.forEach(layer => {
                        if (layer.marker) {
                            const existingObject = this.isObjectInLGOArray(
                                this.objectsList,
                                layer.id,
                                layer.type,
                                layer.layerInfo,
                                layer.subtype || null
                            );
                            // Remove marker if object no longer exists
                            if (!existingObject) {
                                const existingMarker = this.findMapMarker(
                                    layer.id,
                                    layer.type,
                                    layer.layerInfo,
                                    layer.subtype || null
                                );
                                if (existingMarker) this.removeLayerFromLayerGroup(existingMarker, layer.layerInfo);
                            }
                        } else {
                            const existingPath = this.findPathInList(
                                layer.obj1.id,
                                layer.obj1.type,
                                layer.obj2.id,
                                layer.obj2.type,
                                layer.layerInfo
                            );
                            // Remove path if it no longer exists
                            if (!existingPath) {
                                const path = this.findMapPath(
                                    layer.obj1.type,
                                    layer.obj1.id,
                                    layer.obj2.type,
                                    layer.obj2.id,
                                    layer.layerInfo,
                                    layer.obj1.subtype,
                                    layer.obj2.subtype
                                );
                                //
                                const index = this.polylines.indexOf(path);
                                if (index > -1) {
                                    this.polylines.splice(index, 1);
                                }
                                this.removeLayerFromLayerGroup(path, layer.layerInfo);
                            }
                        }
                    });
                }
            });
        }

        // End Loading
        window.setTimeout(() => {
            if (!(this.count > 1)) {
                // Add all groups to map
                for (const key of Object.keys(this.layersControl.overlays)) {
                    const ng: ChildClusterGroup = this.layersControl.overlays[key];
                    // eslint-disable-next-line no-console
                    if (!ng) console.log("problem");
                    // Check if map has saved layers
                    if (this.savedVisibleLayers.length) {
                        // Add saved layers to map
                        if (this.savedVisibleLayers.includes(ng.data.name)) {
                            ng.addTo(this.map);
                        }
                    } else {
                        ng.addTo(this.map);
                    }
                }
                // Center on map objects
                this.recenter();
            }
            this.loadingMap = false;
        }, 0);

        // Uncluster markers if mapDef.cluster
        if (this.mapDefinition && this.count === 0) {
            if (!this.mapDefinition.start_clustered) this.unclusterMarkers();
        }

        // Count
        this.count++;
    }

    // @ts-ignore
    onMapReady(map: L.DrawMap) {
        // Set map
        this.map = map;
        // Fix for tile/base layers not loading initial occasionally
        window.setTimeout(() => {
            this.map.invalidateSize();
        }, 0);
        // Add Zoom Control
        this.map.addControl(L.control.zoom({ position: "topright" }));
        // Track when layer groups are added/removed
        this.map.on("overlayadd overlayremove", () => {
            // Need NgZone so click event isn't slow/unresponsive
            this.zone.run(() => {
                if (this.editMap) this.enableDraggableMarkers();
                else this.disableDraggableMarkers();
                this.currentVisibleLayers = Object.assign([], this.getVisibleLayers());
            });
        });
        // Track base layer change
        this.map.on("baselayerchange", (e: L.LayersControlEvent) => {
            this.zone.run(() => {
                this.savedBaseMap = e.name;
            });
        });
        // Track zoom event end
        this.map.on("zoomend", () => {
            this.zone.run(() => {
                if (this.editMap) this.enableDraggableMarkers();
                else this.disableDraggableMarkers();
            });
        });

        if (this.mapDefinition) this.prepMap(this.mapDefinition);
    }

    toggleLayerGroupVisibility(marker: DataMarker) {
        const layerInfo: LayerInfo = marker.data.layerInfo;
        let lg: ChildClusterGroup;
        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            if (ng.data.channel) {
                if (ng.data.channelID === layerInfo.channelID && ng.data.channelType === layerInfo.channelType) lg = ng;
            } else {
                if (ng.data.layerID === layerInfo.layerID && ng.data.layerID === layerInfo.layerID) lg = ng;
            }
        }
        this.map.addLayer(lg);
    }

    addMarkerToLayerGroup(layer: DataMarker, layerInfo: LayerInfo) {
        let lg: ChildClusterGroup;

        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            if (ng.data.channel) {
                if (ng.data.channelID === layerInfo.channelID && ng.data.channelType === layerInfo.channelType) lg = ng;
            } else {
                if (ng.data.layerID === layerInfo.layerID && ng.data.layerID === layerInfo.layerID) lg = ng;
            }
        }

        // Add marker click event
        layer.on("click", (e: L.LeafletEvent) => {
            this.zone.run(() => {
                this.objectClicked(e);
            });
        });
        // Add marker to layer group
        lg.addLayer(layer);
    }

    addPathToLayerGroup(layer: L.Layer, layerInfo: LayerInfo) {
        let lg: ChildClusterGroup;

        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            if (ng.data.channel) {
                if (ng.data.channelID === layerInfo.channelID && ng.data.channelType === layerInfo.channelType) lg = ng;
            } else {
                if (ng.data.layerID === layerInfo.layerID && ng.data.layerID === layerInfo.layerID) lg = ng;
            }
        }
        // Add path to layer group
        lg.addLayer(layer);
    }

    removeLayerFromLayerGroup(layer: L.Layer, layerInfo: LayerInfo) {
        let lg: ChildClusterGroup;
        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            if (ng.data.channel) {
                if (ng.data.channelID === layerInfo.channelID && ng.data.channelType === layerInfo.channelType) lg = ng;
            } else {
                if (ng.data.layerID === layerInfo.layerID && ng.data.layerID === layerInfo.layerID) lg = ng;
            }
        }
        //
        if (lg) lg.removeLayer(layer);
    }

    hasAccessTag(
        object:
            | Feeder
            | Receiver
            | Zec
            | Broadcaster
            | Source
            | MediaConnectSource
            | UdpRtpTarget
            | NdiTarget
            | RistTarget
            | SrtTarget
            | RtmpPushTarget
            | ZixiPullTarget
            | ZixiPushTarget
            | PublishingTarget,
        term: string
    ) {
        // Access Tags
        return (
            object.resourceTags &&
            Object.values(object.resourceTags).some(tag => tag.name.toLowerCase().includes(term.toLowerCase()))
        );
    }

    matches(
        object:
            | Feeder
            | Receiver
            | Zec
            | Broadcaster
            | Source
            | MediaConnectSource
            | UdpRtpTarget
            | NdiTarget
            | RistTarget
            | SrtTarget
            | RtmpPushTarget
            | ZixiPullTarget
            | ZixiPushTarget
            | PublishingTarget,
        term: string
    ) {
        return (
            // Name
            object.name.toLowerCase().includes(term.toLowerCase())
            // Access Tags
            // (object.resourceTags && Object.values(object.resourceTags).some(tag => tag.name.toLowerCase().includes(term.toLowerCase())))
        );
    }

    tagLookup(val: string) {
        this.tagResults = this.tagsList.filter(t => t.toLowerCase().includes(val.toLowerCase()));
    }

    markerLookup(val: string) {
        const feeders: {
            obj: Feeder;
            layerInfo: LayerInfo;
            type: string;
            subtype: string;
        }[] = [];
        const receivers: {
            obj: Receiver;
            layerInfo: LayerInfo;
            type: string;
            subtype: string;
        }[] = [];
        const zecs: {
            obj: Zec;
            layerInfo: LayerInfo;
            type: string;
            subtype: string;
        }[] = [];
        const broadcasters: {
            obj: Broadcaster;
            layerInfo: LayerInfo;
            type: string;
            subtype: string;
        }[] = [];
        const sources: {
            obj: Source;
            layerInfo: LayerInfo;
            type: string;
            subtype: string;
        }[] = [];
        const mediaconnectSources: {
            obj: MediaConnectSource;
            layerInfo: LayerInfo;
            type: string;
            subtype: string;
        }[] = [];
        const targets: {
            obj:
                | UdpRtpTarget
                | NdiTarget
                | RistTarget
                | SrtTarget
                | RtmpPushTarget
                | ZixiPullTarget
                | ZixiPushTarget
                | PublishingTarget;
            layerInfo: LayerInfo;
            type: string;
            subtype: string;
        }[] = [];
        for (const key of Object.keys(this.objectsList)) {
            const group = this.objectsList[key];
            if (group) {
                if (group.feeders) {
                    const feedersArray = group.feeders.filter(f => this.matches(f, val));
                    for (const f of feedersArray) {
                        const feeder = {
                            obj: f,
                            type: "feeder",
                            subtype: null,
                            layerInfo: {
                                layerID: group.layerID,
                                channel: group.channel,
                                channelID: group.channelID,
                                channelType: group.channelType,
                                name: group.name
                            }
                        };
                        feeders.push(feeder);
                    }
                }
                if (group.receivers) {
                    const receiversArray = group.receivers.filter(r => this.matches(r, val));
                    for (const r of receiversArray) {
                        const receiver = {
                            obj: r,
                            type: "receiver",
                            subtype: null,
                            layerInfo: {
                                layerID: group.layerID,
                                channel: group.channel,
                                channelID: group.channelID,
                                channelType: group.channelType,
                                name: group.name
                            }
                        };
                        receivers.push(receiver);
                    }
                }
                if (group.zecs) {
                    const zecsArray = group.zecs.filter(r => this.matches(r, val));
                    for (const z of zecsArray) {
                        const zec = {
                            obj: z,
                            type: "zec",
                            subtype: null,
                            layerInfo: {
                                layerID: group.layerID,
                                channel: group.channel,
                                channelID: group.channelID,
                                channelType: group.channelType,
                                name: group.name
                            }
                        };
                        zecs.push(zec);
                    }
                }
                if (group.broadcasters) {
                    const broadcastersArray = group.broadcasters.filter(b => this.matches(b, val));
                    for (const b of broadcastersArray) {
                        const broadcaster = {
                            obj: b,
                            type: "broadcaster",
                            subtype: null,
                            layerInfo: {
                                layerID: group.layerID,
                                channel: group.channel,
                                channelID: group.channelID,
                                channelType: group.channelType,
                                name: group.name
                            }
                        };
                        broadcasters.push(broadcaster);
                    }
                }
                if (group.sources) {
                    const sourcesArray = group.sources.filter(s => this.matches(s, val));
                    for (const s of sourcesArray) {
                        const source = {
                            obj: s,
                            type: "source",
                            subtype: null,
                            layerInfo: {
                                layerID: group.layerID,
                                channel: group.channel,
                                channelID: group.channelID,
                                channelType: group.channelType,
                                name: group.name
                            }
                        };
                        sources.push(source);
                    }
                }
                if (group.mediaconnect_sources) {
                    const mediaconnectSourcesArray = group.mediaconnect_sources.filter(mc => this.matches(mc, val));
                    for (const s of mediaconnectSourcesArray) {
                        const mcsource = {
                            obj: s,
                            type: "source",
                            subtype: "mediaconnect",
                            layerInfo: {
                                layerID: group.layerID,
                                channel: group.channel,
                                channelID: group.channelID,
                                channelType: group.channelType,
                                name: group.name
                            }
                        };
                        mediaconnectSources.push(mcsource);
                    }
                }
                if (group.targets) {
                    const targetsArray = group.targets.filter(t => this.matches(t.target, val));
                    for (const t of targetsArray) {
                        const target = {
                            obj: {
                                id: t.target.id,
                                ...t
                            },
                            type: "target",
                            layerInfo: {
                                layerID: group.layerID,
                                channel: group.channel,
                                channelID: group.channelID,
                                channelType: group.channelType,
                                name: group.name
                            },
                            subtype: t.type
                        };
                        targets.push(target);
                    }
                }
            }
        }
        const objects = [
            ...feeders,
            ...receivers,
            ...zecs,
            ...broadcasters,
            ...sources,
            ...mediaconnectSources,
            ...targets
        ];

        // Remove duplicate objects from list
        const uniqueList = objects.reduce((unique, o) => {
            if (!unique.some(i => i.obj.id === o.obj.id && i.type === o.type && i.subtype === o.subtype)) {
                unique.push(o);
            }
            return unique;
        }, []);
        this.markerResults = uniqueList;
    }

    selectTagResult(result: string) {
        const feeders: {
            obj: Feeder;
            layerInfo: LayerInfo;
            type: string;
            subtype: string;
        }[] = [];
        const receivers: {
            obj: Receiver;
            layerInfo: LayerInfo;
            type: string;
            subtype: string;
        }[] = [];
        const zecs: {
            obj: Zec;
            layerInfo: LayerInfo;
            type: string;
            subtype: string;
        }[] = [];
        const broadcasters: {
            obj: Broadcaster;
            layerInfo: LayerInfo;
            type: string;
            subtype: string;
        }[] = [];
        const sources: {
            obj: Source;
            layerInfo: LayerInfo;
            type: string;
            subtype: string;
        }[] = [];
        const mediaconnectSources: {
            obj: MediaConnectSource;
            layerInfo: LayerInfo;
            type: string;
            subtype: string;
        }[] = [];
        const targets: {
            obj:
                | UdpRtpTarget
                | NdiTarget
                | RistTarget
                | SrtTarget
                | RtmpPushTarget
                | ZixiPullTarget
                | ZixiPushTarget
                | PublishingTarget;
            layerInfo: LayerInfo;
            type: string;
            subtype: string;
        }[] = [];
        for (const key of Object.keys(this.objectsList)) {
            const group = this.objectsList[key];
            if (group) {
                if (group.feeders) {
                    const feedersArray = group.feeders.filter(f => this.hasAccessTag(f, result));
                    for (const f of feedersArray) {
                        const feeder = {
                            obj: f,
                            type: "feeder",
                            subtype: null,
                            layerInfo: {
                                layerID: group.layerID,
                                channel: group.channel,
                                channelID: group.channelID,
                                channelType: group.channelType,
                                name: group.name
                            }
                        };
                        feeders.push(feeder);
                    }
                }
                if (group.receivers) {
                    const receiversArray = group.receivers.filter(r => this.hasAccessTag(r, result));
                    for (const r of receiversArray) {
                        const receiver = {
                            obj: r,
                            type: "receiver",
                            subtype: null,
                            layerInfo: {
                                layerID: group.layerID,
                                channel: group.channel,
                                channelID: group.channelID,
                                channelType: group.channelType,
                                name: group.name
                            }
                        };
                        receivers.push(receiver);
                    }
                }
                if (group.zecs) {
                    const zecsArray = group.zecs.filter(z => this.hasAccessTag(z, result));
                    for (const z of zecsArray) {
                        const zec = {
                            obj: z,
                            type: "zec",
                            subtype: null,
                            layerInfo: {
                                layerID: group.layerID,
                                channel: group.channel,
                                channelID: group.channelID,
                                channelType: group.channelType,
                                name: group.name
                            }
                        };
                        zecs.push(zec);
                    }
                }
                if (group.broadcasters) {
                    const broadcastersArray = group.broadcasters.filter(b => this.hasAccessTag(b, result));
                    for (const b of broadcastersArray) {
                        const broadcaster = {
                            obj: b,
                            type: "broadcaster",
                            subtype: null,
                            layerInfo: {
                                layerID: group.layerID,
                                channel: group.channel,
                                channelID: group.channelID,
                                channelType: group.channelType,
                                name: group.name
                            }
                        };
                        broadcasters.push(broadcaster);
                    }
                }
                if (group.sources) {
                    const sourcesArray = group.sources.filter(s => this.hasAccessTag(s, result));
                    for (const s of sourcesArray) {
                        const source = {
                            obj: s,
                            type: "source",
                            subtype: null,
                            layerInfo: {
                                layerID: group.layerID,
                                channel: group.channel,
                                channelID: group.channelID,
                                channelType: group.channelType,
                                name: group.name
                            }
                        };
                        sources.push(source);
                    }
                }
                if (group.mediaconnect_sources) {
                    const mediaconnectSourcesArray = group.mediaconnect_sources.filter(mc =>
                        this.hasAccessTag(mc, result)
                    );
                    for (const s of mediaconnectSourcesArray) {
                        const mcsource = {
                            obj: s,
                            type: "source",
                            subtype: "mediaconnect",
                            layerInfo: {
                                layerID: group.layerID,
                                channel: group.channel,
                                channelID: group.channelID,
                                channelType: group.channelType,
                                name: group.name
                            }
                        };
                        mediaconnectSources.push(mcsource);
                    }
                }
                if (group.targets) {
                    const targetsArray = group.targets.filter(t => this.hasAccessTag(t.target, result));
                    for (const t of targetsArray) {
                        const target = {
                            obj: t.target,
                            type: "target",
                            layerInfo: {
                                layerID: group.layerID,
                                channel: group.channel,
                                channelID: group.channelID,
                                channelType: group.channelType,
                                name: group.name
                            },
                            subtype: t.type
                        };
                        targets.push(target);
                    }
                }
            }
        }
        const objects = [
            ...feeders,
            ...receivers,
            ...zecs,
            ...broadcasters,
            ...sources,
            ...mediaconnectSources,
            ...targets
        ];
        //
        const markers: DataMarker[] = [];
        objects.forEach(o => {
            const marker = this.findMapMarker(o.obj.id, o.type, o.layerInfo, o.subtype || null);
            if (marker) {
                if (marker instanceof DataMarker) {
                    // Make marker layer visible if it's not currently
                    if (!this.map.hasLayer(marker)) this.toggleLayerGroupVisibility(marker);
                    markers.push(marker);
                }
            }
        });
        //
        const group = new L.FeatureGroup(markers);
        const bounds = group.getBounds();
        this.map.fitBounds(bounds, { maxZoom: this.constants.DEFAULT_ZOOM, padding: L.point(32, 48) });
    }

    selectMarkerResult(result: { obj; type: string; layerInfo: LayerInfo; subtype: string }) {
        let marker;
        if (result.type === "target")
            marker = this.findMapMarker(result.obj.target.id, result.type, result.layerInfo, result.subtype || null);
        else marker = this.findMapMarker(result.obj.id, result.type, result.layerInfo, result.subtype || null);
        //
        if (marker) {
            if (marker instanceof DataMarker) {
                const latlng = marker.getLatLng();
                // Update current map point
                this.updateMapPoint(latlng.lat, latlng.lng);
                // Make marker layer visible if it's not currently
                if (!this.map.hasLayer(marker)) this.toggleLayerGroupVisibility(marker);
                // Center on map point and zoom
                this.centerOnLocation(true);
                // Open popup
                this.openMarkerPopup(marker);
            }
        }
    }

    openMarkerPopup(marker: DataMarker) {
        window.setTimeout(() => {
            marker.openTooltip();
            window.setTimeout(() => {
                marker.closeTooltip();
            }, 1000);
        }, 0);
    }

    addressLookup(address: string) {
        this.searchingAddress = true;
        this.showResults = true;
        if (address.length > 3) {
            this.mapService
                .addressLookup(address)
                .pipe(take(1))
                .subscribe(results => {
                    this.searchAddressResults = results;
                    this.searchingAddress = false;
                });
        }
    }

    clearAddressResults() {
        this.showResults = false;
        this.searchAddressResults = [];
        this.searchAddressVal = "";
    }

    centerOnLocation(maxZoom: boolean) {
        const coordinates = L.latLng([this.mapPoint.latitude, this.mapPoint.longitude]);
        if (maxZoom) this.map.setView(coordinates, Constants.MAX_ZOOM);
        else {
            const currentZoom = this.map.getZoom();
            this.map.setView(coordinates, currentZoom);
        }
    }

    centerOnObject(
        type: string,
        object:
            | Feeder
            | Receiver
            | Zec
            | Broadcaster
            | Source
            | MediaConnectSource
            | UdpRtpTarget
            | NdiTarget
            | RistTarget
            | SrtTarget
            | RtmpPushTarget
            | ZixiPullTarget
            | ZixiPushTarget
            | PublishingTarget,
        group: LayerGroupObject,
        subtype: string
    ) {
        let layers;
        let marker: DataMarker;
        let latlng: L.LatLng = null;
        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            if (ng.data.channel) {
                if (ng.data.channelID === group.channelID && ng.data.channelType === group.channelType)
                    layers = ng.getLayers();
            } else {
                if (ng.data.layerID === group.layerID && ng.data.layerID === group.layerID) layers = ng.getLayers();
            }
        }
        //
        layers.forEach((layer: DataMarker) => {
            if (layer instanceof DataMarker) {
                if (type === layer.data.type && object.id === layer.data.id && subtype === layer.data.subtype) {
                    this.selectedNewObject = layer;
                    latlng = layer.getLatLng();
                    marker = layer;
                }
            }
        });
        // Update current map point
        this.updateMapPoint(latlng.lat, latlng.lng);
        // Center on map point
        this.centerOnLocation(true);
        // Open popup
        this.openMarkerPopup(marker);
    }

    async getAllLayerBounds() {
        this.bounds = null;
        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            const layers = ng.getLayers();
            if (layers.length > 0) {
                if (this.map.hasLayer(ng)) {
                    const bounds: L.LatLngBounds = ng.getBounds();
                    if (!this.bounds) this.bounds = bounds;
                    else this.bounds.extend(bounds);
                }
            }
        }
        return this.bounds;
    }

    getDefaultMapRegion() {
        if (!this.mapDefinition || !this.mapDefinition.region) return Constants.regionCenter.north_america;
        switch (this.mapDefinition.region) {
            case "africa":
                return Constants.regionCenter.africa;
            case "asia":
                return Constants.regionCenter.asia;
            case "australia_oceania":
                return Constants.regionCenter.australia_oceania;
            case "europe":
                return Constants.regionCenter.europe;
            case "north_america":
                return Constants.regionCenter.north_america;
            case "south_america":
                return Constants.regionCenter.south_america;
            default:
                return Constants.regionCenter.north_america;
        }
    }

    async recenter() {
        await this.getAllLayerBounds();
        if (this.bounds)
            this.map.fitBounds(this.bounds, { maxZoom: this.constants.DEFAULT_ZOOM, padding: L.point(32, 48) });
        else this.map.setView(this.getDefaultMapRegion(), 3);
    }

    async refresh() {
        if (!this.editMap) {
            this.stopAutoRefresh();
            this.refreshingMap = true;
            // Refresh all objects
            await this.mapService.refreshMap(this.mapID, true).toPromise();
            // Refresh selected object
            if (
                this.selectedMapObject &&
                this.selectedMapObject.data &&
                this.selectedMapObject.data.type &&
                this.selectedMapObject.data.id
            ) {
                if (this.selectedMapObject.data.type === "feeder") {
                    // Feeder
                    await this.zecsService.refreshZec(this.selectedMapObject.data.id, "FEEDER", true).toPromise();
                    this.currentFeeder = Object.assign(
                        {},
                        this.zecsService.getCachedZec("FEEDER", null, this.selectedMapObject.data.id)
                    ) as Feeder;
                } else if (this.selectedMapObject.data.type === "receiver") {
                    // Receiver
                    await this.zecsService.refreshZec(this.selectedMapObject.data.id, "RECEIVER", true).toPromise();
                    this.currentReceiver = Object.assign(
                        {},
                        this.zecsService.getCachedZec("RECEIVER", null, this.selectedMapObject.data.id)
                    ) as Receiver;
                } else if (this.selectedMapObject.data.type === "zec") {
                    // Zec
                    await this.zecsService.refreshZec(this.selectedMapObject.data.id, "ZEC", true).toPromise();
                    this.currentZec = Object.assign(
                        {},
                        this.zecsService.getCachedZec("ZEC", null, this.selectedMapObject.data.id)
                    ) as Zec;
                } else if (this.selectedMapObject.data.type === "broadcaster") {
                    // Broadcaster
                    await this.broadcastersService.refreshBroadcaster(this.selectedMapObject.data.id, true).toPromise();
                    this.currentBroadcaster = Object.assign(
                        {},
                        this.broadcastersService.getCachedBroadcaster(this.selectedMapObject.data.id)
                    );
                } else if (
                    this.selectedMapObject.data.type === "source" &&
                    this.selectedMapObject.data.subtype !== "mediaconnect"
                ) {
                    // Source
                    await this.sourcesService.refreshSource(this.selectedMapObject.data.id, true).toPromise();
                    const source = this.sourcesService.getCachedSource(null, null, this.selectedMapObject.data.id);
                    this.currentSource = Object.assign({}, source);
                } else if (
                    this.selectedMapObject.data.type === "source" &&
                    this.selectedMapObject.data.subtype === "mediaconnect"
                ) {
                    // MC Source
                    const MCsource = await this.mediaConnectSourcesService
                        .refreshMediaConnectSource(this.selectedMapObject.data.id, true)
                        .toPromise();
                    this.currentMediaConnectSource = Object.assign({}, MCsource);
                } else if (this.selectedMapObject.data.type === "target") {
                    // Target
                    await this.targetsService
                        .refreshTarget(this.selectedMapObject.data.subtype, this.selectedMapObject.data.id, true)
                        .toPromise();
                    this.currentTarget = Object.assign(
                        {},
                        this.targetsService.getCachedTarget(
                            this.selectedMapObject.data.id,
                            this.selectedMapObject.data.subtype
                        )
                    );
                }
            }
            this.refreshingMap = false;
            this.startAutoRefresh();
        }
    }

    private updateMapPoint(latitude: number, longitude: number) {
        this.mapPoint = {
            latitude,
            longitude
        };
    }

    enableDraggableMarkers() {
        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            const layers = ng.getLayers();
            layers.forEach((layer: DataMarker) => {
                if (layer instanceof L.Marker) {
                    if (this.map.hasLayer(layer)) {
                        layer.dragging.enable();
                    }
                }
            });
        }
    }

    disableDraggableMarkers() {
        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            const layers = ng.getLayers();
            layers.forEach((layer: DataMarker) => {
                if (layer instanceof L.Marker) {
                    if (this.map.hasLayer(layer)) {
                        layer.dragging.disable();
                    }
                }
            });
        }
    }

    enableEdit() {
        this.editMap = true;
        this.unclusterMarkers();
        this.enableDraggableMarkers();
    }

    cancelEdit() {
        this.editMap = false;
        this.clusterMarkers();
        this.disableDraggableMarkers();
        this.redrawFromBackup();
        this.showDetails = false;
        this.selectedMapObject = null;
        this.unsavedChanges = false;
        this.movingMarkerByAddress = false;
    }

    clearLayers() {
        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            ng.clearLayers();
        }
    }

    redrawFromBackup() {
        this.clearLayers();
        this.count = 0;
        this.mapService.refreshMap(this.mapDefinition.id, true);
    }

    async saveMap() {
        this.savingMap = true;
        this.disableDraggableMarkers();
        //
        const leafletData: LayerGroupData[] = [];
        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            const ngl = ng.getLayers();
            const layers: MarkerData[] = [];
            ngl.forEach((layer: L.Layer) => {
                if (layer instanceof DataMarker) {
                    const markerData: MarkerData = {
                        marker: true,
                        id: layer.data.id,
                        type: layer.data.type,
                        subtype: layer.data.subtype,
                        latlng: layer.getLatLng(),
                        layerInfo: {
                            name: layer.data.layerInfo.name,
                            channel: layer.data.layerInfo.channel,
                            channelID: layer.data.layerInfo.channelID,
                            channelType: layer.data.layerInfo.channelType,
                            layerID: layer.data.layerInfo.layerID
                        }
                    };
                    // Check if marker has been moved/placed by user
                    const unplaced = this.isObjectInLGOArray(
                        this.unplacedObjects,
                        layer.data.id,
                        layer.data.type,
                        layer.data.layerInfo,
                        layer.data.subtype || null
                    );
                    const locationPlaced = this.isObjectInLGOArray(
                        this.locationPlacedObjects,
                        layer.data.id,
                        layer.data.type,
                        layer.data.layerInfo,
                        layer.data.subtype || null
                    );
                    if (!unplaced && !locationPlaced) layers.push(markerData);
                }
            });
            leafletData.push({
                visible: this.map.hasLayer(ng),
                layers,
                layerInfo: {
                    name: ng.data.name,
                    channel: ng.data.channel,
                    channelID: ng.data.channelID,
                    channelType: ng.data.channelType,
                    layerID: ng.data.layerID
                }
            });
        }

        const model = {
            name: this.mapDefinition.name,
            type: this.mapDefinition.type,
            region: this.mapDefinition.region,
            baseMap: this.mapDefinition.baseMap,
            config: {
                mediaconnect_flows: this.mapDefinition.config.mediaconnect_flows || [],
                adaptive_channels: this.mapDefinition.config.adaptive_channels || [],
                delivery_channels: this.mapDefinition.config.delivery_channels || [],
                groups: this.mapDefinition.config.groups || [],
                leafletData
            }
        };
        await this.mapService.updateMap(this.mapDefinition.id, model);
        this.editMap = false;
        this.savingMap = false;
        this.unsavedChanges = false;
    }

    resetObjects() {
        this.currentFeeder = null;
        this.currentReceiver = null;
        this.currentZec = null;
        this.currentSource = null;
        this.currentBroadcaster = null;
        this.currentMediaConnectSource = null;
        this.currentTarget = null;
        if (this.selectedMapObject) {
            // Remove selected class from icon
            this.removeSelectedClassFromMarker();
        }
    }

    removeSelectedClassFromMarker() {
        const icon: L.DivIcon = this.selectedMapObject.getIcon();
        let className = icon.options.className;
        className = className.replace(/ selected/g, "");
        icon.options.className = className;
        this.selectedMapObject.setIcon(icon);
    }

    addSelectedClassToMarker() {
        const icon: L.DivIcon = this.selectedMapObject.getIcon();
        let className = icon.options.className;
        className = className + " selected";
        icon.options.className = className;
        this.selectedMapObject.setIcon(icon);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateMarker(type: string, object: any, subtype: string) {
        // Letter
        const letter = this.markerLetter(type, subtype);
        // Status
        const status = this.markerStatus(type, object);
        // Healthscore
        const healthScore = this.getHealthScore(type, object);
        // Marker Class
        const className = this.markerClass(type, status);
        // Icon
        const newIcon = this.markerIcon(type, object, className, healthScore, letter);
        const cName = newIcon.options.className;
        newIcon.options.className = cName + " selected";
        // Tooltip
        const tooltip = this.markerToolip(type, object, subtype);
        this.selectedMapObject.unbindTooltip();
        this.selectedMapObject.bindTooltip(tooltip, { interactive: true, sticky: true });
        this.selectedMapObject.setIcon(newIcon);
    }

    async objectClicked(i: L.LeafletEvent, d?: DataMarker) {
        this.resetObjects();
        this.clearAddressResults();
        this.showDetails = true;
        this.loadingDetails = true;

        if (d) this.selectedMapObject = d;
        else this.selectedMapObject = i.target;
        // Set Icon class to selected
        // this.addSelectedClassToMarker();
        // Get Current Zoom
        // const currentZoom = this.map.getZoom();
        // Center View on clicked object
        // this.map.setView(i.latlng, currentZoom);
        // Check for Type and ID
        if (this.selectedMapObject.data && this.selectedMapObject.data.type && this.selectedMapObject.data.id) {
            if (this.selectedMapObject.data.type === "feeder") {
                // Feeder
                await this.zecsService.refreshZec(this.selectedMapObject.data.id, "FEEDER", true).toPromise();
                this.currentFeeder = Object.assign(
                    {},
                    this.zecsService.getCachedZec("FEEDER", null, this.selectedMapObject.data.id)
                ) as Feeder;
                this.updateMarker("feeder", this.currentFeeder, null);
            } else if (this.selectedMapObject.data.type === "receiver") {
                // Receiver
                await this.zecsService.refreshZec(this.selectedMapObject.data.id, "RECEIVER", true).toPromise();
                this.currentReceiver = Object.assign(
                    {},
                    this.zecsService.getCachedZec("RECEIVER", null, this.selectedMapObject.data.id)
                ) as Receiver;
                this.updateMarker("receiver", this.currentReceiver, null);
            } else if (this.selectedMapObject.data.type === "zec") {
                // Zec
                await this.zecsService.refreshZec(this.selectedMapObject.data.id, "ZEC", true).toPromise();
                this.currentZec = Object.assign(
                    {},
                    this.zecsService.getCachedZec("ZEC", null, this.selectedMapObject.data.id)
                ) as Zec;
                this.updateMarker("zec", this.currentZec, null);
            } else if (this.selectedMapObject.data.type === "broadcaster") {
                // Broadcaster
                await this.broadcastersService.refreshBroadcaster(this.selectedMapObject.data.id, true).toPromise();
                this.currentBroadcaster = Object.assign(
                    {},
                    this.broadcastersService.getCachedBroadcaster(this.selectedMapObject.data.id)
                );
                this.updateMarker("broadcaster", this.currentBroadcaster, null);
            } else if (
                this.selectedMapObject.data.type === "source" &&
                this.selectedMapObject.data.subtype !== "mediaconnect"
            ) {
                // Source
                await this.sourcesService.refreshSource(this.selectedMapObject.data.id, true).toPromise();
                const source = this.sourcesService.getCachedSource(null, null, this.selectedMapObject.data.id);
                this.currentSource = Object.assign({}, source);
                this.updateMarker("source", this.currentSource, null);
            } else if (
                this.selectedMapObject.data.type === "source" &&
                this.selectedMapObject.data.subtype === "mediaconnect"
            ) {
                // MC Source
                const MCsource = await this.mediaConnectSourcesService
                    .refreshMediaConnectSource(this.selectedMapObject.data.id, true)
                    .toPromise();
                this.currentMediaConnectSource = Object.assign({}, MCsource);
                this.updateMarker("source", this.currentMediaConnectSource, "mediaconnect");
            } else if (this.selectedMapObject.data.type === "target") {
                // Target
                await this.targetsService
                    .refreshTarget(this.selectedMapObject.data.subtype, this.selectedMapObject.data.id, true)
                    .toPromise();
                this.currentTarget = Object.assign(
                    {},
                    this.targetsService.getCachedTarget(
                        this.selectedMapObject.data.id,
                        this.selectedMapObject.data.subtype
                    )
                );
                this.updateMarker("target", this.currentTarget.target, this.selectedMapObject.data.subtype);
            }
        }
        this.loadingDetails = false;
    }

    closeDetails() {
        this.showDetails = false;
        this.movingMarkerByAddress = false;
        // Remove selected class from icon
        this.removeSelectedClassFromMarker();
        //
        this.selectedMapObject = null;
    }

    closeNewObjects() {
        this.showNewObjects = false;
    }

    moveMarkerClick() {
        if (!this.editMap) {
            this.enableEdit();
        }
        this.movingMarkerByAddress = !this.movingMarkerByAddress;
    }

    // Drag functions
    dragStartHandler(e: L.LeafletEvent) {
        const target: DataMarker = e.target;
        this.movingMarkers.push(target);
        // Remove object from unplaced objects when moved
        this.unplacedObjects = this.removeObjectFromLGOArray(this.unplacedObjects, target);
        // Remove object from location placed objects when moved
        this.locationPlacedObjects = this.removeObjectFromLGOArray(this.locationPlacedObjects, target);
        // Find connected polylines
        this.findConnectedPaths(target);
        // Find duplicate markers
        this.findAllDuplicateMapMarkers(target.data.id, target.data.type, target.data.subtype || null);
    }

    dragHandler(e: L.LeafletEvent) {
        // Move markers
        if (this.movingMarkers) {
            this.movingMarkers.forEach(m => {
                if (m instanceof DataMarker) {
                    const latlng: L.LatLng = e.target.getLatLng();
                    this.findConnectedPaths(m);
                    m.setLatLng(latlng);
                }
            });
        }
        // Move lines
        if (this.movingPolylines) {
            this.movingPolylines.forEach(mp => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let latlngs: any = mp.polyline.getLatLngs();
                const latlng: L.LatLng = e.target.getLatLng();
                // Check if latlng is nested in another array
                if (Array.isArray(latlngs) && (Array.isArray(latlngs[0]) || Array.isArray(latlngs[1]))) {
                    latlngs = latlngs[0];
                }
                latlngs.splice(mp.i, 1, latlng);
                mp.polyline.setLatLngs(latlngs);
            });
        }
        //
        this.unsavedChanges = true;
    }

    dragEndHandler() {
        this.movingPolylines = [];
        this.movingMarkers = [];
    }

    moveMarkerByAddress(result: NominatimResponse) {
        this.updateMapPoint(result.latitude, result.longitude);
        // Find connected polylines
        this.findConnectedPaths(this.selectedMapObject);
        // Find duplicate markers
        this.findAllDuplicateMapMarkers(
            this.selectedMapObject.data.id,
            this.selectedMapObject.data.type,
            this.selectedMapObject.data.subtype || null
        );
        // Move markers
        if (this.movingMarkers) {
            this.movingMarkers.forEach(m => {
                if (m instanceof DataMarker) {
                    this.findConnectedPaths(m);
                    m.setLatLng(L.latLng(this.mapPoint.latitude, this.mapPoint.longitude));
                }
            });
        }
        // Move lines
        if (this.movingPolylines) {
            this.movingPolylines.forEach(mp => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let latlngs: any = mp.polyline.getLatLngs();
                // Check if latlng is nested in another array
                if (Array.isArray(latlngs) && (Array.isArray(latlngs[0]) || Array.isArray(latlngs[1]))) {
                    latlngs = latlngs[0];
                }
                latlngs.splice(mp.i, 1, L.latLng(this.mapPoint.latitude, this.mapPoint.longitude));
                mp.polyline.setLatLngs(latlngs);
            });
        }
        this.selectedMapObject.setLatLng({ lat: this.mapPoint.latitude, lng: this.mapPoint.longitude });
        // Remove object from unplaced objects when moved
        this.unplacedObjects = this.removeObjectFromLGOArray(this.unplacedObjects, this.selectedMapObject);
        // Remove object from location placed objects when moved
        this.locationPlacedObjects = this.removeObjectFromLGOArray(this.locationPlacedObjects, this.selectedMapObject);
        // Center map on new marker address
        this.centerOnLocation(false);
        //
        this.unsavedChanges = true;
        // this.movingPolylines = [];
        // this.movingMarkers = [];
    }

    // Leaflet Groups Process
    leafletGroupsProcess(mapDef: Map) {
        mapDef.config.groups.forEach(group => {
            // Setup Layer Groups
            if (!this.sharedService.isEmptyObject(this.layersControl.overlays)) {
                if (!_.has(this.layersControl.overlays, group.name)) {
                    const subGroup = new GoodChildClusterGroup(this.parentGroup, null, {
                        name: group.name,
                        layerID: group.id
                    });
                    this.layersControl.overlays[group.name] = subGroup;
                }
            } else {
                const subGroup = new GoodChildClusterGroup(this.parentGroup, null, {
                    name: group.name,
                    layerID: group.id
                });
                this.layersControl.overlays[group.name] = subGroup;
            }
            // Setup Objects
            // Feeders
            if (group.feeders && group.feeders.length) {
                group.feeders.forEach((id: number) => {
                    const feeder = this.zecsService.getCachedZec("FEEDER", null, id);
                    if (feeder) this.addObject("feeder", feeder, { name: group.name, layerID: group.id }, null);
                });
            }
            // Receivers
            if (group.receivers && group.receivers.length) {
                group.receivers.forEach((id: number) => {
                    const receiver = this.zecsService.getCachedZec("RECEIVER", null, id);
                    if (receiver) this.addObject("receiver", receiver, { name: group.name, layerID: group.id }, null);
                });
            }
            // Zecs
            if (group.zecs && group.zecs.length) {
                group.zecs.forEach((id: number) => {
                    const zec = this.zecsService.getCachedZec("ZEC", null, id);
                    if (zec) this.addObject("zec", zec, { name: group.name, layerID: group.id }, null);
                });
            }
            // Broadcasters
            if (group.broadcasters && group.broadcasters.length) {
                group.broadcasters.forEach((id: number) => {
                    const broadcaster = this.broadcastersService.getCachedBroadcaster(id);
                    if (broadcaster)
                        this.addObject("broadcaster", broadcaster, { name: group.name, layerID: group.id }, null);
                });
            }
            // Sources
            if (group.sources && group.sources.length) {
                group.sources.forEach((id: number) => {
                    const source = this.sourcesService.getCachedSource(null, null, id);
                    if (source) this.addObject("source", source, { name: group.name, layerID: group.id }, null);
                });
            }
            // MediaConnect Sources
            if (group.mediaconnect_sources && group.mediaconnect_sources.length) {
                group.mediaconnect_sources.forEach((id: number) => {
                    const mcSource = this.mediaConnectSourcesService.getCachedMediaConnectSource(null, id);
                    if (mcSource)
                        this.addObject("source", mcSource, { name: group.name, layerID: group.id }, "mediaconnect");
                });
            }
            // Targets
            if (group.targets) {
                group.targets.forEach((typeID: string) => {
                    const arr = typeID.split("-");
                    const type = arr[0];
                    const id = parseInt(arr[1], 10);

                    const target = this.targetsService.getCachedTarget(id, type);
                    if (target)
                        this.addObject(
                            "target",
                            target.target,
                            { name: group.name, layerID: group.id },
                            this.targetsService.getTargetApiType(target.target)
                        );
                });
            }
        });
    }

    // Leaflet Channel Process
    async leafletChannelProcess(
        channel: AdaptiveChannel | DeliveryChannel | MediaConnectFlow,
        channelName: string,
        type: string
    ) {
        const channelInfo = {
            name: channelName,
            channel: true,
            channelID: channel.id,
            channelType: type
        };

        // Setup Layer Groups
        if (!this.sharedService.isEmptyObject(this.layersControl.overlays)) {
            if (!_.has(this.layersControl.overlays, channelName)) {
                const subGroup = new GoodChildClusterGroup(this.parentGroup, null, channelInfo);
                this.layersControl.overlays[channelName] = subGroup;
            }
        } else {
            const subGroup = new GoodChildClusterGroup(this.parentGroup, null, channelInfo);
            this.layersControl.overlays[channelName] = subGroup;
        }

        // Setup Sources
        const sources = [];
        if (channel.delivery) {
            for (const src of channel.sources) {
                await this.sourcesService.refreshSource(src.source).toPromise();
                const s = Object.assign({}, this.sourcesService.getCachedSource(null, null, src.source.id));
                sources.push(s);
            }
        }

        if (channel.mediaconnect) {
            if (channel.source && channel.source.id != null) {
                await this.mediaConnectSourcesService.refreshMediaConnectSource(channel.source).toPromise();
                const s = Object.assign(
                    {},
                    this.mediaConnectSourcesService.getCachedMediaConnectSource(null, channel.source.id)
                );
                sources.push(s);
            }
        }

        if (channel.adaptive) {
            for (const bitrate of channel.bitrates) {
                await this.sourcesService.refreshSource(bitrate.source).toPromise();
                const s = Object.assign({}, this.sourcesService.getCachedSource(null, null, bitrate.source.id));
                sources.push(s);
            }
        }

        // Setup Active Broadcasters
        let activeBroadcasters = [];
        if (channel.delivery && channel.status && channel.status.active_broadcasters) {
            activeBroadcasters = channel.status.active_broadcasters.map(ab => {
                return Object.assign({}, ab, _.find(channel.processingCluster.broadcasters, { id: ab.id }));
            });
        } else if (channel.adaptive && channel.status && channel.status.active_broadcaster) {
            activeBroadcasters = [channel.status.active_broadcaster].map(ab => {
                return Object.assign({}, ab, _.find(channel.processingCluster.broadcasters, { id: ab.id }));
            });
        }

        let channelActiveBroadcasters = [];
        for (const broadcaster of activeBroadcasters) {
            await this.broadcastersService.refreshBroadcaster(broadcaster.id).toPromise();
            const b = this.broadcastersService.getCachedBroadcaster(broadcaster.id);
            channelActiveBroadcasters.push(b);
        }

        if (channel.delivery || channel.adaptive) {
            if (channel.processingCluster) {
                for (const b of channel.processingCluster.broadcasters) {
                    await this.broadcastersService.refreshBroadcaster(b.id).toPromise();
                    const broadcaster = Object.assign({}, this.broadcastersService.getCachedBroadcaster(b.id));
                    this.addObject("broadcaster", broadcaster, channelInfo, null);
                }
            }
        }

        // Sources
        for (const s of sources) {
            await this.addSource(channel, channelActiveBroadcasters, s, channelInfo);
        }

        // Publishing Target
        if (channel.adaptive) {
            for (const t of channel.publishingTarget) {
                await this.targetsService.refreshTarget(this.targetsService.getTargetApiType(t), t.id).toPromise();
                const target = this.targetsService.getCachedTarget(t.id, this.targetsService.getTargetApiType(t));
                await this.addTarget(channel, target.target, channelInfo, "http");
            }
        }

        if (channel.delivery) {
            // rtmp Push
            for (const t of channel.rtmpPush) {
                await this.targetsService.refreshTarget(this.targetsService.getTargetApiType(t), t.id).toPromise();
                const target = this.targetsService.getCachedTarget(t.id, this.targetsService.getTargetApiType(t));
                await this.addTarget(channel, target.target, channelInfo, "rtmp");
            }

            // RIST
            for (const t of channel.rist) {
                await this.targetsService.refreshTarget(this.targetsService.getTargetApiType(t), t.id).toPromise();
                const target = this.targetsService.getCachedTarget(t.id, this.targetsService.getTargetApiType(t));
                await this.addTarget(channel, target.target, channelInfo, "rist");
            }

            // SRT
            for (const t of channel.srt) {
                await this.targetsService.refreshTarget(this.targetsService.getTargetApiType(t), t.id).toPromise();
                const target = this.targetsService.getCachedTarget(t.id, this.targetsService.getTargetApiType(t));
                await this.addTarget(channel, target.target, channelInfo, "srt");
            }

            // NDI
            for (const t of channel.ndi) {
                await this.targetsService.refreshTarget(this.targetsService.getTargetApiType(t), t.id).toPromise();
                const target = this.targetsService.getCachedTarget(t.id, this.targetsService.getTargetApiType(t));
                await this.addTarget(channel, target.target, channelInfo, "ndi");
            }
        }

        if (channel.delivery || channel.mediaconnect) {
            // Zixi Push
            for (const t of channel.zixiPush) {
                await this.targetsService.refreshTarget(this.targetsService.getTargetApiType(t), t.id).toPromise();
                const target = this.targetsService.getCachedTarget(t.id, this.targetsService.getTargetApiType(t));
                await this.addTarget(channel, target.target, channelInfo, "push");
            }

            // udpRtp
            for (const t of channel.udpRtp) {
                await this.targetsService.refreshTarget(this.targetsService.getTargetApiType(t), t.id).toPromise();
                const target = this.targetsService.getCachedTarget(t.id, this.targetsService.getTargetApiType(t));
                await this.addTarget(channel, target.target, channelInfo, "udp_rtp");
            }

            // Zixi Pull
            for (const t of channel.zixiPull) {
                await this.targetsService.refreshTarget(this.targetsService.getTargetApiType(t), t.id).toPromise();
                const target = this.targetsService.getCachedTarget(t.id, this.targetsService.getTargetApiType(t));
                await this.addTarget(channel, target.target, channelInfo, "pull");

                const typedTarget: ZixiPullTarget = target.target as ZixiPullTarget;

                if (typedTarget.receiver_id && typedTarget.receiver) {
                    await this.zecsService.refreshZec(typedTarget.receiver.id, "RECEIVER").toPromise();
                    const receiver = this.zecsService.getCachedZec("RECEIVER", null, typedTarget.receiver.id);
                    this.addObject("receiver", receiver, channelInfo, null);
                    this.addPath("target", typedTarget, "receiver", receiver, channelInfo, "pull", null);
                } else if (typedTarget.zec_id && typedTarget.zec) {
                    await this.zecsService.refreshZec(typedTarget.zec.id, "ZEC").toPromise();
                    const zec = this.zecsService.getCachedZec("ZEC", null, typedTarget.zec.id);
                    this.addObject("zec", zec, channelInfo, null);
                    this.addPath("target", typedTarget, "zec", zec, channelInfo, "pull", null);
                } else if (typedTarget.broadcaster_id && typedTarget.broadcaster) {
                    await this.broadcastersService.refreshBroadcaster(typedTarget.broadcaster.id).toPromise();
                    const broadcaster = this.broadcastersService.getCachedBroadcaster(typedTarget.broadcaster.id);
                    this.addObject("broadcaster", broadcaster, channelInfo, null);
                    this.addPath("target", typedTarget, "broadcaster", broadcaster, channelInfo, "pull", null);
                }
            }
        }
    }

    // Add Target
    async addTarget(
        selectedChannel: AdaptiveChannel | DeliveryChannel | MediaConnectFlow,
        target,
        channelInfo: {
            name: string;
            channel: boolean;
            channelID: number;
            channelType: string;
        },
        type: string
    ) {
        if (target.readOnly) return;
        this.addObject("target", target, channelInfo, type);

        if (selectedChannel.adaptive || selectedChannel.delivery) {
            let activeBroadcasters = [];
            if (target.status && target.status.active_broadcasters)
                activeBroadcasters = target.status.active_broadcasters;
            else if (target.status && target.status.active_broadcaster)
                activeBroadcasters = [target.status.active_broadcaster];

            let actualBroadcaster: Broadcaster | null = null;
            if (activeBroadcasters.length > 0) {
                await this.broadcastersService.refreshBroadcaster(activeBroadcasters[0].id, false).toPromise();
                actualBroadcaster = this.broadcastersService.getCachedBroadcaster(activeBroadcasters[0].id);
            }

            if (actualBroadcaster) {
                await this.broadcastersService.refreshBroadcaster(actualBroadcaster.id).toPromise();
                const b = this.broadcastersService.getCachedBroadcaster(actualBroadcaster.id);
                this.addObject("broadcaster", b, channelInfo, null);
                this.addPath("broadcaster", b, "target", target, channelInfo, null, type);
            }

            if (activeBroadcasters[0]?.source_id) {
                await this.sourcesService.refreshSource(activeBroadcasters[0]?.source_id).toPromise();
                const s = this.sourcesService.getCachedSource(null, null, activeBroadcasters[0]?.source_id);
                this.addPath("source", s, "target", target, channelInfo, null, type);
            } else if (target.preferred_source > 0) {
                await this.sourcesService.refreshSource(target.preferred_source).toPromise();
                const s = this.sourcesService.getCachedSource(null, null, target.preferred_source);
                this.addPath("source", s, "target", target, channelInfo, null, type);
            }
        }
    }

    // Add Source
    async addSource(
        selectedChannel: AdaptiveChannel | DeliveryChannel | MediaConnectFlow,
        channelActiveBroadcasters: ActiveBroadcaster[],
        source: Source | MediaConnectSource,
        channelInfo: {
            name: string;
            channel: boolean;
            channelID: number;
            channelType: string;
        },
        standaloneSource?: boolean
    ) {
        if (!source.hasFullDetails) {
            if (source.mediaconnect) {
                await this.mediaConnectSourcesService.refreshMediaConnectSource(source.id);
                source = this.mediaConnectSourcesService.getCachedMediaConnectSource(null, source.id);
            } else {
                await this.sourcesService.refreshSource(source.id).toPromise();
                source = this.sourcesService.getCachedSource(null, null, source.id);
            }
        }

        // Source Object & Status
        if (source.mediaconnect) {
            if (!source.mediaconnect_flow_id) this.addObject("source", source, channelInfo, null);
            // MediaConnect Source Object & Status
            else this.addObject("source", source, channelInfo, "mediaconnect");
        } else if (source.zixi) {
            this.addObject("source", source, channelInfo, null);

            // inputClusters
            if (source.inputCluster) {
                for (const b of source.inputCluster.broadcasters) {
                    await this.broadcastersService.refreshBroadcaster(b.id).toPromise();
                    const broadcaster = this.broadcastersService.getCachedBroadcaster(b.id);
                    this.addObject("broadcaster", broadcaster, channelInfo, null);
                }
            }

            // Hitless Failover Sources
            if (source.hitless_failover_source_ids && source.failoverSources && source.failoverSources.length) {
                for (const failover of source.failoverSources) {
                    this.addSource(selectedChannel, channelActiveBroadcasters, failover.source, channelInfo, true);
                    //
                    if (
                        failover.source.status &&
                        failover.source.status.active_broadcaster &&
                        failover.source.status.active_broadcaster.id
                    ) {
                        this.addPath(
                            "broadcaster",
                            failover.source.status.active_broadcaster,
                            "source",
                            source,
                            channelInfo,
                            null,
                            null
                        );
                    }
                }
            }

            // Transcoded Sources
            if (source.transcodeSource && source.transcodeSource.id) {
                this.addSource(selectedChannel, channelActiveBroadcasters, source.transcodeSource, channelInfo, true);
                if (
                    source.transcodeSource.status &&
                    source.transcodeSource.status.active_broadcaster &&
                    source.transcodeSource.status.active_broadcaster.id
                ) {
                    this.addPath(
                        "broadcaster",
                        source.transcodeSource.status.active_broadcaster,
                        "source",
                        source,
                        channelInfo,
                        null,
                        null
                    );
                }
            }

            // Intercluster/Chained Source
            if (source.Source && source.Source.id) {
                this.addSource(selectedChannel, channelActiveBroadcasters, source.Source, channelInfo, true);
                if (
                    source.Source.status &&
                    source.Source.status.active_broadcaster &&
                    source.Source.status.active_broadcaster.id
                ) {
                    this.addPath(
                        "broadcaster",
                        source.Source.status.active_broadcaster,
                        "source",
                        source,
                        channelInfo,
                        null,
                        null
                    );
                }
            }

            let sourceActiveBroadcaster = null;
            const channelActiveBroadcaster = _.find(channelActiveBroadcasters, bx => {
                // adaptive channel broadcaster has no source_stream_id since it's always just the one broadcaster
                // pass-through channel broadcasters are identified by source_stream_id since each source will have a different broadcaster
                return !bx.source_stream_id || bx.source_stream_id === source.stream_id;
            });

            if (source.status && source.status.active_broadcaster)
                sourceActiveBroadcaster = _.find(source.inputCluster.broadcasters, {
                    id: source.status.active_broadcaster.id
                });

            // Source Broadcasters
            if (sourceActiveBroadcaster) {
                await this.broadcastersService.refreshBroadcaster(sourceActiveBroadcaster.id).toPromise();
                const broadcaster = this.broadcastersService.getCachedBroadcaster(sourceActiveBroadcaster.id);
                this.addObject("broadcaster", broadcaster, channelInfo, null);
                this.addPath("source", source, "broadcaster", broadcaster, channelInfo, null, null);

                if (
                    (selectedChannel.adaptive || selectedChannel.delivery) &&
                    source.broadcaster_cluster_id !== selectedChannel.broadcaster_cluster_id &&
                    !standaloneSource
                ) {
                    if (channelActiveBroadcaster) {
                        this.addObject("broadcaster", channelActiveBroadcaster, channelInfo, null);
                        this.addPath(
                            "broadcaster",
                            sourceActiveBroadcaster,
                            "broadcaster",
                            channelActiveBroadcaster,
                            channelInfo,
                            null,
                            null
                        );
                    }
                }
            }

            // Source Feeder
            if (source.feeder_id && source.feeder) {
                await this.zecsService.refreshZec(source.feeder.id, "FEEDER").toPromise();
                const feeder = this.zecsService.getCachedZec("FEEDER", null, source.feeder.id);
                // Source Feeder Object & Status
                this.addObject("feeder", feeder, channelInfo, null);
                // Feeder to Source Path
                this.addPath("feeder", feeder, "source", source, channelInfo, null, null);
            }

            // Source Broadcaster
            if (source.broadcaster_id && source.broadcaster) {
                await this.broadcastersService.refreshBroadcaster(source.broadcaster_id).toPromise();
                const broadcaster = this.broadcastersService.getCachedBroadcaster(source.broadcaster_id);
                // Source Broadcaster Object & Status
                this.addObject("broadcaster", broadcaster, channelInfo, null);
                // Broadcaster to Source Path
                this.addPath("broadcaster", broadcaster, "source", source, channelInfo, null, null);
            }
        }
    }

    addAccessTagstoList(
        object:
            | Feeder
            | Receiver
            | Zec
            | Broadcaster
            | Source
            | MediaConnectSource
            | UdpRtpTarget
            | NdiTarget
            | RistTarget
            | SrtTarget
            | RtmpPushTarget
            | ZixiPullTarget
            | ZixiPushTarget
            | PublishingTarget
    ): string[] {
        const tags = _.map(object.resourceTags, "name");
        let jointArray = [];
        jointArray = [...tags, ...this.tagsList];
        const uniqueArray = jointArray.filter((item, index) => jointArray.indexOf(item) === index);
        return uniqueArray;
    }

    isObjectInLGOArray(
        list: LayerGroupObject[],
        id: number,
        type: string,
        layerInfo: LayerInfo,
        subtype: string
    ): boolean {
        if (!list) return false;
        //
        let group;
        if (!layerInfo.channel) {
            group = list.find(o => o.layerID === layerInfo.layerID);
        } else {
            group = list.find(o => o.channelID === layerInfo.channelID && o.channelType === layerInfo.channelType);
        }
        //
        if (group) {
            if (type === "feeder") {
                if (group.feeders) {
                    const feeder = group.feeders.find(f => f.id === id);
                    if (feeder) return true;
                }
            } else if (type === "receiver") {
                if (group.receivers) {
                    const receiver = group.receivers.find(r => r.id === id);
                    if (receiver) return true;
                }
            } else if (type === "zec") {
                if (group.zecs) {
                    const zec = group.zecs.find(z => z.id === id);
                    if (zec) return true;
                }
            } else if (type === "broadcaster") {
                if (group.broadcasters) {
                    const broadcaster = group.broadcasters.find(b => b.id === id);
                    if (broadcaster) return true;
                }
            } else if (type === "source" && subtype !== "mediaconnect") {
                if (group.sources) {
                    const source = group.sources.find(s => s.id === id);
                    if (source) return true;
                }
            } else if (type === "source" && subtype === "mediaconnect") {
                if (group.mediaconnect_sources) {
                    const mcSource = group.mediaconnect_sources.find(mc => mc.id === id);
                    if (mcSource) return true;
                }
            } else if (type === "target") {
                if (group.targets) {
                    const target = group.targets.find(t => t.target.id === id && t.type === subtype);
                    if (target) return true;
                }
            }
        }
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addObjectToLGOArray(
        list: LayerGroupObject[],
        object: any,
        type: string,
        layerInfo: LayerInfo,
        subtype: string
    ): LayerGroupObject[] {
        if (!list) return;
        //
        if (!layerInfo.channel) {
            if (!list.find(o => o.layerID === layerInfo.layerID)) {
                list.push({ name: layerInfo.name, layerID: layerInfo.layerID });
            }
        } else {
            if (!list.find(o => o.channelID === layerInfo.channelID && o.channelType === layerInfo.channelType))
                list.push({
                    name: layerInfo.name,
                    channel: true,
                    channelID: layerInfo.channelID,
                    channelType: layerInfo.channelType
                });
        }
        //
        let group;
        if (!layerInfo.channel) {
            group = list.find(o => o.layerID === layerInfo.layerID);
        } else {
            group = list.find(o => o.channelID === layerInfo.channelID && o.channelType === layerInfo.channelType);
        }
        //
        if (group) {
            if (type === "feeder") {
                if (!group.feeders) group.feeders = [];
                if (!group.feeders.find(o => o.id === object.id)) group.feeders.push(object);
            } else if (type === "receiver") {
                if (!group.receivers) group.receivers = [];
                if (!group.receivers.find(o => o.id === object.id)) group.receivers.push(object);
            } else if (type === "zec") {
                if (!group.zecs) group.zecs = [];
                if (!group.zecs.find(o => o.id === object.id)) group.zecs.push(object);
            } else if (type === "broadcaster") {
                if (!group.broadcasters) group.broadcasters = [];
                if (!group.broadcasters.find(o => o.id === object.id)) group.broadcasters.push(object);
            } else if (type === "source" && subtype !== "mediaconnect") {
                if (!group.sources) group.sources = [];
                if (!group.sources.find(o => o.id === object.id)) group.sources.push(object);
            } else if (type === "source" && subtype === "mediaconnect") {
                if (!group.mediaconnect_sources) group.mediaconnect_sources = [];
                if (!group.mediaconnect_sources.find(o => o.id === object.id)) group.mediaconnect_sources.push(object);
            } else if (type === "target") {
                if (!group.targets) group.targets = [];
                if (!group.targets.find(o => o.type === subtype && o.target.id === object.id))
                    group.targets.push({ target: object, type: subtype });
            }
        }
        return list;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    removeObjectFromLGOArray(list: LayerGroupObject[], object: any): LayerGroupObject[] {
        let objectType;
        if (object.data.type === "feeder") objectType = "feeders";
        else if (object.data.type === "receiver") objectType = "receivers";
        else if (object.data.type === "zec") objectType = "zecs";
        else if (object.data.type === "broadcaster") objectType = "broadcasters";
        else if (object.data.type === "source" && object.data.subtype !== "mediaconnect") objectType = "sources";
        else if (object.data.type === "source" && object.data.subtype === "mediaconnect")
            objectType = "mediaconnect_sources";
        else if (object.data.type === "target") objectType = "targets";

        let channelName;
        if (object._eventParents) {
            for (const key of Object.keys(object._eventParents)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const parent: any = object._eventParents[key];
                if (parent instanceof GoodChildClusterGroup) {
                    channelName = parent.data.name;
                }
            }
        }

        const group = list.find(lgo => lgo.name === channelName);
        if (objectType && group) {
            const l = group[objectType];
            if (l) {
                if (objectType !== "targets") {
                    const index = l.findIndex(i => {
                        return i.id === object.data.id;
                    });
                    if (index !== -1) l.splice(index, 1);
                } else {
                    const index = l.findIndex(i => {
                        return i.target.id === object.data.id && i.type === object.data.subtype;
                    });
                    if (index !== -1) l.splice(index, 1);
                }
            }
        }

        // Remove group from list if it has no objects
        if (
            group &&
            (!group.feeders || group.feeders.length === 0) &&
            (!group.receivers || group.receivers.length === 0) &&
            (!group.zecs || group.zecs.length === 0) &&
            (!group.broadcasters || group.broadcasters.length === 0) &&
            (!group.sources || group.sources.length === 0) &&
            (!group.mediaconnect_sources || group.mediaconnect_sources.length === 0) &&
            (!group.targets || group.targets.length === 0)
        ) {
            list = list.filter(lgo => lgo.name !== channelName);
        }

        return list;
    }

    findPathInList(id1: number, type1: string, id2: number, type2: string, layerInfo: LayerInfo): boolean {
        const p = this.currentPolylines.find(polyline => {
            const polylineData = polyline.options["data"];
            if (!layerInfo.channel) {
                if (
                    polylineData.layerInfo.layerID === layerInfo.layerID &&
                    polylineData.obj1.id === id1 &&
                    polylineData.obj1.type === type1 &&
                    polylineData.obj2.id === id2 &&
                    polylineData.obj2.type === type2
                )
                    return polyline;
            } else {
                if (
                    polylineData.layerInfo.channelID === layerInfo.channelID &&
                    polylineData.layerInfo.channelType === layerInfo.channelType &&
                    polylineData.obj1.id === id1 &&
                    polylineData.obj1.type === type1 &&
                    polylineData.obj2.id === id2 &&
                    polylineData.obj2.type === type2
                )
                    return polyline;
            }
        });
        if (p) return true;
        return false;
    }

    // Determine State
    determineState(o, isCluster?: boolean, checkChannel?: boolean) {
        let channelDisabled: boolean;
        if (checkChannel) {
            const channel =
                (o.adaptive_channel_id != null && o.adaptiveChannel) ||
                (o.delivery_channel_id != null && o.deliveryChannel) ||
                (o.mediaconnect_flow_id != null && o.mediaconnectFlow);
            if (channel && !channel.is_enabled) channelDisabled = true;
        }
        //
        if (channelDisabled) o.STATE = "disabled";
        else if (
            o.generalStatus === "no_source" ||
            o.generalStatus === "no_flow" ||
            o.generalStatus === "flow_disabled" ||
            o.generalStatus === "no_channel"
        )
            o.STATE = "disabled";
        else if (o.objectState && (o.objectState.state === "error" || o.objectState.state === "warning"))
            o.STATE = o.objectState.state;
        else if (isCluster || o.is_enabled) o.STATE = o.state === "pending" ? o.state : o.generalStatus;
        else o.STATE = "disabled";
        return o.STATE;
    }

    // Get Status Icon
    getStatusIcon(status) {
        if (status) {
            // Disabled or No
            if (
                status === "no" ||
                status === "disabled" ||
                status === "no_source" ||
                status === "no_flow" ||
                status === "flow_disabled" ||
                status === "no_channel"
            ) {
                return "<i class='fa fa-ban fa-sm status-disabled'></i>&nbsp;";
            } // Good
            else if (status === "good") {
                return "<i class='fa fa-check-circle fa-sm status-good'></i>&nbsp;";
                // Bad
            } else if (status === "bad" || status === "error") {
                return "<i class='fa fa-minus-circle fa-sm status-bad'></i>&nbsp;";
                // Pending
            } else if (status === "pending") {
                return "<i class='fa fa-dot-circle fa-sm status-pending'></i>&nbsp;";
                // Default
            } else if (status === "warning" || status === "med") {
                return "<i class='fa fa-exclamation-circle fa-sm status-warning'></i>&nbsp;";
                // Default
            } else {
                return "<i class='fa fa-circle fa-sm'></i>&nbsp;";
            }
        }
    }

    // URL Content
    urlContent(url) {
        return "<span class='url'>" + url + "</span>&nbsp;";
    }

    broadcasterContent(b) {
        // Check If Broadcaster Exists
        if (b.name) {
            let Icon = "";
            let displayError = "";
            // Status Icon
            Icon = this.getStatusIcon(this.determineState(b));
            // Error Message
            const errorState = this.sharedService.getLastError(b);
            if (errorState) {
                displayError =
                    "<span class='item'><span class='property'>Error:</span>&nbsp;" +
                    "<span class='value error' title='" +
                    errorState.message.replace(/"|'/g, "`") +
                    "'>" +
                    errorState.short_message +
                    "</span></span>";
            }
            return (
                "<span class='name'><span class='status'>" + Icon + "</span><span>" + b.name + "</span>" + displayError
            );
        }
    }

    // Feeder Content
    feederContent(f) {
        let Icon = "";
        let displayError = "";
        // Status Icon
        Icon = this.getStatusIcon(this.determineState(f));
        // Error Message
        const errorState = this.sharedService.getLastError(f);
        if (errorState) {
            displayError =
                "<span class='item'><span class='property'>Error:</span>&nbsp;" +
                "<span class='value error' title='" +
                errorState.message.replace(/"|'/g, "`") +
                "'>" +
                errorState.short_message +
                "</span></span>";
        }
        return "<span class='name'><span class='status'>" + Icon + "</span>" + f.name + "</span>" + displayError;
    }

    // Receiver Content
    receiverContent(r) {
        let Icon = "";
        let displayError = "";
        // Status Icon
        Icon = this.getStatusIcon(this.determineState(r));
        // Error Message
        const errorState = this.sharedService.getLastError(r);
        if (errorState) {
            displayError =
                "<span class='item'><span class='property'>Error:</span>&nbsp;" +
                "<span class='value error' title='" +
                errorState.message.replace(/"|'/g, "`") +
                "'>" +
                errorState.short_message +
                "</span></span>";
        }
        return "<span class='name'><span class='status'>" + Icon + "</span><span>" + r.name + "</span>" + displayError;
    }

    // Zec Content
    zecContent(z) {
        let Icon = "";
        let displayError = "";
        // Status Icon
        Icon = this.getStatusIcon(this.determineState(z));
        // Error Message
        const errorState = this.sharedService.getLastError(z);
        if (errorState) {
            displayError =
                "<span class='item'><span class='property'>Error:</span>&nbsp;" +
                "<span class='value error' title='" +
                errorState.message.replace(/"|'/g, "`") +
                "'>" +
                errorState.short_message +
                "</span></span>";
        }
        return "<span class='name'><span class='status'>" + Icon + "</span><span>" + z.name + "</span>" + displayError;
    }

    // Target Content
    targetContent(t) {
        let Icon = "";
        let displayError = "";
        // Status Icon
        Icon = this.getStatusIcon(this.determineState(t, null, true));
        // Error Message
        const errorState = this.sharedService.getLastError(t);
        if (errorState) {
            displayError =
                "<span class='item'><span class='property'>Error:</span>&nbsp;" +
                "<span class='value error' title='" +
                errorState.message.replace(/"|'/g, "`") +
                "'>" +
                errorState.short_message +
                "</span></span>";
        }
        return "<span class='name'><span class='status'>" + Icon + "</span><span>" + t.name + "</span>" + displayError;
    }

    // Source Content
    sourceContent(s) {
        let Icon = "";
        let displayError = "";
        let healthScore = "";
        let healthIcon = "";
        // Status Icon
        Icon = this.getStatusIcon(this.determineState(s));
        // Error Message
        const errorState = this.sharedService.getLastError(s);
        if (errorState) {
            displayError =
                "<span class='item'><span class='property'>Error:</span>&nbsp;" +
                "<span class='value error' title='" +
                errorState.message.replace(/"|'/g, "`") +
                "'>" +
                errorState.short_message +
                "</span></span>";
        }
        // Health Score
        if (this.healthMode) {
            if (s.health && s.health?.healthScore != null) {
                const score = s.health?.healthScore;
                const status =
                    score === null
                        ? "none"
                        : score <= Constants.healthScoreThresholds.error
                        ? "bad"
                        : score > Constants.healthScoreThresholds.error && score <= Constants.healthScoreThresholds.good
                        ? "warning"
                        : score > Constants.healthScoreThresholds.good
                        ? "good"
                        : "none";

                healthIcon = this.getStatusIcon(status);

                healthScore =
                    "<span class='item'><span class='property'>Health:</span>&nbsp;" +
                    "<span>" +
                    healthIcon +
                    Math.round(parseFloat(s.health?.healthScore)) +
                    "</span></span>";
            }
        }
        return (
            "<span class='name'><span class='status'>" +
            Icon +
            "</span><span>" +
            s.name +
            "</a></span>" +
            healthScore +
            displayError
        );
    }

    markerLetter(type: string, subtype: string): string {
        if (type === "feeder") {
            return "F";
        } else if (type === "broadcaster") {
            return "B";
        } else if (type === "source" && subtype !== "mediaconnect") {
            return "S";
        } else if (type === "source" && subtype === "mediaconnect") {
            return "S";
        } else if (type === "target") {
            return "T";
        } else if (type === "receiver") {
            return "R";
        } else if (type === "zec") {
            return "Z";
        } else {
            return "";
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    markerToolip(type: string, object: any, subtype: string): string {
        if (type === "feeder") {
            return this.feederContent(object);
        } else if (type === "broadcaster") {
            return this.broadcasterContent(object);
        } else if (type === "source" && subtype !== "mediaconnect") {
            return this.sourceContent(object);
        } else if (type === "source" && subtype === "mediaconnect") {
            return this.sourceContent(object);
        } else if (type === "target") {
            return this.targetContent(object);
        } else if (type === "receiver") {
            return this.receiverContent(object);
        } else if (type === "zec") {
            return this.zecContent(object);
        } else {
            return "";
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    markerStatus(type: string, object: any): string {
        let status = this.statusClassPipe.transform(object.generalStatus);
        if (type === "target") {
            const channel =
                (object.adaptive_channel_id != null && object.adaptiveChannel) ||
                (object.delivery_channel_id != null && object.deliveryChannel) ||
                (object.mediaconnect_flow_id != null && object.mediaconnectFlow);
            if (channel && !channel.is_enabled) status = "disabled";
        }
        return status;
    }

    markerClass(type: string, status: string) {
        if (type === "source") return "customMarker sourceMarker status-" + status;
        else if (type === "target") return "customMarker targetMarker status-" + status;
        else return "customMarker status-" + status;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    markerIcon(type: string, object: any, className: string, healthScore, letter: string) {
        if (this.healthMode && type === "source") {
            let status =
                healthScore === null
                    ? "missing"
                    : healthScore <= Constants.healthScoreThresholds.error
                    ? "error"
                    : healthScore > Constants.healthScoreThresholds.error &&
                      healthScore <= Constants.healthScoreThresholds.good
                    ? "warning"
                    : healthScore > Constants.healthScoreThresholds.good
                    ? "good"
                    : "none";

            if (status === "missing") status = this.statusClassPipe.transform(object.generalStatus);
            className = "customMarker sourceMarker status-" + status;

            return L.divIcon({
                className,
                iconAnchor: [0, 24],
                popupAnchor: [0, -36],
                html: `<div><span>` + letter + `</span></div>`
            });
        } else {
            return L.divIcon({
                className,
                iconAnchor: [0, 24],
                popupAnchor: [0, -36],
                html: `<div><span>` + letter + `</span></div>`
            });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getHealthScore(type: string, object: any) {
        if (type === "source" && object.health && object.health?.healthScore != null) {
            return object.health?.healthScore;
        } else return null;
    }

    // Add Object & Status
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addObject(type: string, object: any, layerInfo: LayerInfo, subtype: string) {
        // Add to Objects List
        this.objectsList = this.addObjectToLGOArray(this.objectsList, object, type, layerInfo, subtype || null);
        // Add Access Tags to Tags List
        this.tagsList = this.addAccessTagstoList(object);
        // Letter and Tooltip Content
        const letter = this.markerLetter(type, subtype);
        const tooltip = this.markerToolip(type, object, subtype);
        // Status
        const status = this.markerStatus(type, object);
        // Healthscore
        const healthScore = this.getHealthScore(type, object);
        // Marker Class
        const className = this.markerClass(type, status);
        // Icon
        const icon = this.markerIcon(type, object, className, healthScore, letter);
        // Set LatLng from Map definition
        let latlng: L.LatLng = this.findDataObjLatLng(object.id, type, layerInfo, subtype || null);
        let latlnglit: L.LatLngLiteral = null;
        // Check if marker already exists on any layer
        const existingMarkerDiffLayer = this.findMapMarkerAnyLayer(object.id, type, subtype || null);
        if (existingMarkerDiffLayer) {
            if (existingMarkerDiffLayer instanceof DataMarker) {
                latlng = existingMarkerDiffLayer.getLatLng();
            }
        }

        if (!latlng || !latlng.lat || !latlng.lng) {
            let location;
            if (object.location) {
                if (object.location?.address) {
                    location = object.location.address;
                } else {
                    location = object.location.ip;
                }
            }

            // Get lat/lng from database
            if (location && location.latitude && location.longitude) {
                // Set IP based lat/lng
                latlnglit = { lat: location.latitude, lng: location.longitude };
                // Add object without set lat/lng to ip based objects
                this.locationPlacedObjects = this.addObjectToLGOArray(
                    this.locationPlacedObjects,
                    object,
                    type,
                    layerInfo,
                    subtype || null
                );
            } else {
                // Set generated lat/lng
                latlnglit = this.generateLatLng(layerInfo.name);
                // Add object without set lat/lng to unplaced objects
                this.unplacedObjects = this.addObjectToLGOArray(
                    this.unplacedObjects,
                    object,
                    type,
                    layerInfo,
                    subtype || null
                );
            }
        } else latlnglit = { lat: latlng.lat, lng: latlng.lng };
        //
        // Check if marker already exists on this layer and update icon if it does
        const existingMarker = this.findMapMarker(object.id, type, layerInfo, subtype || null);
        let isSelected = false;
        if (existingMarker) {
            if (existingMarker instanceof DataMarker) {
                // Update marker health score
                existingMarker.data.healthScore = healthScore;
                // If marker exists and status has changed remove it
                let cName = existingMarker.options.icon.options.className;
                // If marker is selected
                if (cName.includes("selected")) isSelected = true;
                // Remove selected from class
                cName = cName.replace(/ selected/g, "");
                // TODO: update this to use new update marker function instead of remove?
                // If marker is source always remove it and redraw
                if (cName !== icon.options.className || (this.healthMode && type === "source")) {
                    this.removeLayerFromLayerGroup(existingMarker, layerInfo);
                } else return;
            }
        }
        // Marker
        const marker = new DataMarker(
            [latlnglit.lat, latlnglit.lng],
            { icon, draggable: false, riseOnHover: true, autoPan: true },
            { type, id: object.id, layerInfo, subtype: subtype || null, healthScore: healthScore || null }
        )
            .bindTooltip(tooltip, { interactive: true, sticky: true })
            .on("dragstart", e => {
                this.dragStartHandler(e);
            })
            .on("drag", e => {
                this.dragHandler(e);
            })
            .on("dragend", () => {
                this.dragEndHandler();
            });
        // Add Layer to Group
        this.addMarkerToLayerGroup(marker, layerInfo);
        // Reselect marker if it was redrawn
        if (isSelected) {
            this.selectedMapObject = marker;
            this.addSelectedClassToMarker();
        }
    }

    addPath(type1: string, object1, type2: string, object2, layerInfo: LayerInfo, subtype1: string, subtype2: string) {
        let ng: ChildClusterGroup;
        for (const key of Object.keys(this.layersControl.overlays)) {
            const namedGroup: ChildClusterGroup = this.layersControl.overlays[key];
            if (namedGroup.data.channel) {
                if (
                    namedGroup.data.channelID === layerInfo.channelID &&
                    namedGroup.data.channelType === layerInfo.channelType
                )
                    ng = namedGroup;
            } else {
                if (namedGroup.data.layerID === layerInfo.layerID && namedGroup.data.layerID === layerInfo.layerID)
                    ng = namedGroup;
            }
        }

        const layers = ng.getLayers();
        let obj1: L.LatLng = null;
        let obj2: L.LatLng = null;

        layers.forEach((layer: DataMarker) => {
            if (layer instanceof DataMarker) {
                if (type1 === layer.data.type && object1.id === layer.data.id && subtype1 === layer.data.subtype) {
                    obj1 = layer.getLatLng();
                }
                if (type2 === layer.data.type && object2.id === layer.data.id && subtype2 === layer.data.subtype) {
                    obj2 = layer.getLatLng();
                }
            }
        });

        if (obj1 && obj2) {
            const status1 = this.statusClassPipe.transform(object1.generalStatus);
            const status2 = this.statusClassPipe.transform(object2.generalStatus);

            const options = {
                use: L.polyline,
                delay: 3000,
                paused: false,
                dashArray: [10, 20],
                weight: 5,
                color: Constants.colors.none,
                pulseColor: "#e0e0e0",
                hardwareAccelerated: true,
                hardwareAcceleration: true,
                interactive: false,
                renderer: L.svg({ pane: "overlayPane" }),
                opacity: 0.5,
                data: {
                    obj1: {
                        id: object1.id,
                        type: type1,
                        subtype: subtype1 || null,
                        healthScore: object1.health?.healthScore || null
                    },
                    obj2: {
                        id: object2.id,
                        type: type2,
                        subtype: subtype2 || null,
                        healthScore: object2.health?.healthScore || null
                    },
                    layerInfo
                }
            };
            // Set good line status
            let status;
            if (
                ((type1 === "source" || type1 === "target") && status1 === "good") ||
                ((type2 === "source" || type2 === "target") && status2 === "good") ||
                (type1 === "broadcaster" && type2 === "broadcaster" && status1 === "good" && status2 === "good")
            ) {
                status = "good";
                options.pulseColor = "#4CAF50";
                options.opacity = 0.9;
                options.color = Constants.colors.good;
                options.delay = 1000;
            }
            // Source specific
            if (this.healthMode && (type1 === "source" || type2 === "source")) {
                // Set Health Score
                const healthScore1 =
                    options.data.obj1.healthScore === null
                        ? "transparent"
                        : options.data.obj1.healthScore <= Constants.healthScoreThresholds.error
                        ? "error"
                        : options.data.obj1.healthScore > Constants.healthScoreThresholds.error &&
                          options.data.obj1.healthScore <= Constants.healthScoreThresholds.good
                        ? "warning"
                        : options.data.obj1.healthScore > Constants.healthScoreThresholds.good
                        ? "good"
                        : "none";

                const healthScore2 =
                    options.data.obj2.healthScore === null
                        ? "transparent"
                        : options.data.obj2.healthScore <= Constants.healthScoreThresholds.error
                        ? "error"
                        : options.data.obj2.healthScore > Constants.healthScoreThresholds.error &&
                          options.data.obj2.healthScore <= Constants.healthScoreThresholds.good
                        ? "warning"
                        : options.data.obj2.healthScore > Constants.healthScoreThresholds.good
                        ? "good"
                        : "none";

                // Warning
                if (healthScore1 === "warning" || healthScore2 === "warning") {
                    status = "warning";
                    options.pulseColor = "#FF9800";
                    options.opacity = 0.9;
                    options.color = Constants.colors.warning;
                    options.delay = 2000;
                }

                // Error
                if (healthScore1 === "error" || healthScore2 === "error") {
                    status = "error";
                    options.pulseColor = "#E57373";
                    options.opacity = 0.9;
                    options.color = Constants.colors.error;
                    options.delay = 3000;
                }
            }
            //
            const antPolyline: AntPath = new AntPath([obj1, obj2], options);
            // TODO: setting antpath id manually hasn't worked since moving to markerclusters
            // Create typings file for antpath to try to resolve that and animation issue permanently?
            // Set antPolyline id, because by default it uses current time which can produce duplicate IDs
            // antPolyline._animatedPathId = `ant-path-${this.pathId++}`;
            // Add Line to currentPolylines
            this.currentPolylines.push(antPolyline);
            // Check if line already exists in layer group
            const existingPath = this.findMapPath(type1, object1.id, type2, object2.id, layerInfo, subtype1, subtype2);
            if (existingPath) {
                // If line exists and status hasn't changed, just update health score
                if (
                    (status === "good" && existingPath.options.color === Constants.colors.good) ||
                    (status === "warning" && existingPath.options.color === Constants.colors.warning) ||
                    (status === "error" && existingPath.options.color === Constants.colors.error) ||
                    (!status && existingPath.options.color === Constants.colors.none)
                ) {
                    // Update Health Score
                    existingPath.options.data = options.data;
                    return;
                } else {
                    // If line exists and status has changed remove it
                    const index = this.polylines.indexOf(existingPath);
                    if (index > -1) {
                        this.polylines.splice(index, 1);
                    }
                    this.removeLayerFromLayerGroup(existingPath, layerInfo);
                }
            }
            // Add Line to Polylines
            this.polylines.push(antPolyline);
            // Add Layer to Group
            this.addPathToLayerGroup(antPolyline, layerInfo);
        } else {
            // eslint-disable-next-line no-console
            console.log("can't place path");
        }
    }

    generateLatLng(groupName: string) {
        const defaultLatLng = this.getDefaultMapRegion();
        if (this.groupNames.length > 0) {
            if (!this.groupNames.find(g => g === groupName)) {
                this.groupNames.push(groupName);
                this.baseLat = defaultLatLng.lat;
                if (!this.baseLng) {
                    this.baseLng = defaultLatLng.lng;
                } else {
                    this.baseLng += 0.5;
                }
            } else {
                this.baseLat += 0.1;
                this.baseLng += 0.1;
            }
        } else {
            this.groupNames.push(groupName);
            this.baseLat = defaultLatLng.lat;
            this.baseLng = defaultLatLng.lng;
        }
        return { lat: this.baseLat, lng: this.baseLng };
    }

    resetLatLng() {
        this.groupNames = [];
        this.baseLat = null;
        this.baseLng = null;
    }

    findMapPath(
        type1: string,
        id1: number,
        type2: string,
        id2: number,
        layerInfo: LayerInfo,
        subtype1: string,
        subtype2: string
    ) {
        let lg: ChildClusterGroup;
        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            if (ng.data.channel) {
                if (ng.data.channelID === layerInfo.channelID && ng.data.channelType === layerInfo.channelType) lg = ng;
            } else {
                if (ng.data.layerID === layerInfo.layerID && ng.data.layerID === layerInfo.layerID) lg = ng;
            }
        }
        if (lg) {
            const layers = lg.getLayers();
            let path;
            layers.find(layer => {
                if (!(layer instanceof DataMarker)) {
                    const data = layer["options"].data;
                    if (
                        data.obj1.id === id1 &&
                        data.obj1.type === type1 &&
                        data.obj1.subtype === subtype1 &&
                        data.obj2.id === id2 &&
                        data.obj2.type === type2 &&
                        data.obj2.subtype === subtype2
                    ) {
                        path = layer;
                    }
                }
            });
            return path;
        }
    }

    findMapMarker(id: number, type: string, layerInfo: LayerInfo, subtype: string) {
        let lg: ChildClusterGroup;
        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            if (ng.data.channel) {
                if (ng.data.channelID === layerInfo.channelID && ng.data.channelType === layerInfo.channelType) lg = ng;
            } else {
                if (ng.data.layerID === layerInfo.layerID && ng.data.layerID === layerInfo.layerID) lg = ng;
            }
        }
        if (lg) {
            const layers = lg.getLayers();
            const found = layers.find(
                (l: DataMarker) => l.data && l.data.type === type && l.data.id === id && l.data.subtype === subtype
            );
            if (found) return found;
        }
        return false;
    }

    findMapMarkerAnyLayer(id: number, type: string, subtype: string) {
        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            const layers = ng.getLayers();
            const found = layers.find(
                (l: DataMarker) => l.data && l.data.type === type && l.data.id === id && l.data.subtype === subtype
            );
            if (found) return found;
        }
        return false;
    }

    findAllDuplicateMapMarkers(id: number, type: string, subtype: string) {
        this.movingMarkers = [];
        for (const key of Object.keys(this.layersControl.overlays)) {
            const ng: ChildClusterGroup = this.layersControl.overlays[key];
            const layers = ng.getLayers();
            const found = layers.find(
                (l: DataMarker) => l.data && l.data.type === type && l.data.id === id && l.data.subtype === subtype
            );
            if (found) this.movingMarkers.push(found);
        }
    }

    findConnectedPaths(target: DataMarker) {
        if (target.getLatLng()) {
            this.polylines.forEach((polyline: L.Polyline) => {
                // Marker and Line have common object data
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let latlngs: any = polyline.getLatLngs();
                const latlng: L.LatLng = target.getLatLng();
                // Check if latlng is nested in another array
                if (Array.isArray(latlngs) && (Array.isArray(latlngs[0]) || Array.isArray(latlngs[1]))) {
                    latlngs = latlngs[0];
                }
                latlngs.forEach((l, indx: number) => {
                    // Marker and Line have common lat/lng
                    if (l === latlng) {
                        const polylineData = polyline.options["data"];
                        // Marker and Line have common object data
                        if (
                            (polylineData.obj1.id === target.data.id &&
                                polylineData.obj1.type === target.data.type &&
                                polylineData.obj1.subtype === target.data.subtype) ||
                            (polylineData.obj2.id === target.data.id &&
                                polylineData.obj2.type === target.data.type &&
                                polylineData.obj2.subtype === target.data.subtype)
                        ) {
                            if (this.movingPolylines.find(o => o.polyline === polyline)) return;
                            else this.movingPolylines.push({ polyline, i: indx });
                        }
                    }
                });
            });
        }
    }

    findDataObjLatLng(id: number, type: string, data: LayerInfo, subtype: string): L.LatLng {
        if (!this.mapDefinition || !this.mapDefinition.config.leafletData) return;
        //
        let group;
        if (data.channel) {
            group = this.mapDefinition.config.leafletData.find(
                lg => lg.layerInfo.channelID === data.channelID && lg.layerInfo.channelType === data.channelType
            );
        } else {
            group = this.mapDefinition.config.leafletData.find(lg => lg.layerInfo.layerID === data.layerID);
        }
        //
        if (!group) return;
        let latlng: L.LatLng = null;
        let layer = null;
        layer = group.layers.find(m => m.id === id && m.type === type && m.subtype === subtype);
        if (layer) latlng = layer.latlng;
        //
        return latlng;
    }

    exportToExcel() {
        // Columns headers
        const defaultColumns = ["name", "processing_cluster", "type", "status", "error", "message", "access_tags"];
        const optionalColumns = [
            "bitrate_kbps",
            "tr101",
            "latency",
            "ip",
            "cpu_%",
            "ram_%",
            "in_bitrate_kbps",
            "out_bitrate_kbps",
            "version"
        ];
        const visibleColumns = defaultColumns.concat(optionalColumns);

        const headersForExcel = [];
        visibleColumns.forEach(column => {
            headersForExcel.push(this.translate.instant(column.toUpperCase()));
        });

        // Column data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const objects: { object: any; type: string }[] = [];
        for (const key of Object.keys(this.objectsList)) {
            const group = this.objectsList[key];
            if (group) {
                if (group.feeders) {
                    for (const f of group.feeders) {
                        objects.push({ object: f, type: "feeder" });
                    }
                }
                if (group.receivers) {
                    for (const r of group.receivers) {
                        objects.push({ object: r, type: "receiver" });
                    }
                }
                if (group.zecs) {
                    for (const r of group.zecs) {
                        objects.push({ object: r, type: "zec" });
                    }
                }
                if (group.broadcasters) {
                    for (const b of group.broadcasters) {
                        objects.push({ object: b, type: "broadcaster" });
                    }
                }
                if (group.sources) {
                    for (const s of group.sources) {
                        objects.push({ object: s, type: "source" });
                    }
                }
                if (group.mediaconnect_sources) {
                    for (const mc of group.mediaconnect_sources) {
                        objects.push({ object: mc, type: "mediaconnect_source" });
                    }
                }
                if (group.targets) {
                    for (const t of group.targets) {
                        objects.push({ object: t.target, type: "target" });
                    }
                }
            }
        }

        // Remove duplicate objects from list
        const uniqueList = objects.reduce((unique, o) => {
            if (
                !unique.some(i => i.object.id === o.object.id && i.object.name === o.object.name && i.type === o.type)
            ) {
                unique.push(o);
            }
            return unique;
        }, []);

        // Data
        const dataForExcel = [];
        uniqueList.forEach(o => {
            const values = [];
            // Name
            values.push(o.object.name);
            // Cluster Name
            let clusterName = "";
            // Input Cluster Name
            if (o.object.inputCluster && o.object.inputCluster.name) clusterName = o.object.inputCluster.name;
            // Processing Cluster Name
            if (o.object.processingCluster && o.object.processingCluster.name)
                clusterName = o.object.processingCluster.name;
            // MediaConnect Flow Name
            if (o.object.mediaconnectFlow && o.object.mediaconnectFlow.name)
                clusterName = o.object.mediaconnectFlow.name;
            values.push(clusterName);
            // Type
            values.push(
                o.object.type
                    ? this.translate.instant(o.object.type.toUpperCase())
                    : this.translate.instant(o.type.toUpperCase())
            );
            // Status
            values.push(this.translate.instant(this.stp.transform(o.object)));
            // Error
            values.push(o.activeStates?.map(as => as.short_message)?.join(","));
            // Message
            values.push(o.activeStates?.map(as => as.message)?.join(","));
            // Access Tags
            values.push(_.map(o.object.resourceTags, "name").toString());
            // Bitrate
            values.push(o.object.status?.bitrate);
            // TR101
            let tr101 = "";
            if (o.object.status && o.object.status?.tr101 && o.object.status?.tr101?.status) {
                if (o.object.status.tr101.status.p1_ok) tr101 += "P1(Ok) ";
                else tr101 += "P1(Error) ";
                if (o.object.status.tr101.status.p2_ok) tr101 += "P2(Ok)";
                else tr101 += "P2(Error)";
            }
            values.push(tr101);
            // Latency
            values.push(o.object.latency);
            // IP
            values.push(o.object.status?.source_ip);
            // CPU
            values.push(o.object.status?.cpu);
            // RAM
            values.push(o.object.status?.ram);
            // Bandwidth
            values.push(o.object.status?.input_kbps);
            values.push(o.object.status?.output_kbps);
            // Version
            values.push(o.object.status?.about?.version);
            dataForExcel.push(values);
        });

        this.excelService.exportMapExcel(
            dataForExcel,
            headersForExcel,
            this.translate.instant("MAP"),
            this.mapDefinition?.name
        );
    }
}
