<div class="form-group row">
    <div class="col">
        <zx-feeder [model]="feeder" [showStatusIcon]="true" [showStatusText]="false" [showLink]="true" [showTag]="true"></zx-feeder>
    </div>
</div>
<div class="form-group row" *ngIf="feeder.resourceTags && feeder.resourceTags.length">
    <h5 class="col-12" translate>TAGS</h5>
    <div class="col-12">
        <span *ngFor="let tag of feeder.resourceTags; index as i"><span *ngIf="i > 0">, </span>{{ tag.name }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="feeder.alertingProfile">
    <h5 class="col-12" translate>ALERTING_PROFILE</h5>
    <div class="col-12">
        <span>{{ feeder.alertingProfile.name }}</span>
    </div>
</div>
<div class="form-group row">
    <h5 class="col-12" translate>API_USER</h5>
    <div class="col-12">
        <span>{{ feeder.api_user }}</span>
    </div>
</div>
<div class="form-group row">
    <h5 class="col-12" translate>API_CREDENTIALS</h5>
    <div class="col-12">
        <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible"> {{ "SHOW" | translate }} </a>
        <span *ngIf="pwVisible">
            {{ feeder.api_user }} / {{ feeder.api_password }}
            <app-password-strength-icon [password]="feeder.api_password"> </app-password-strength-icon>
        </span>
        <br />
        <a href="javascript:void(0)" (click)="copyPassword(feeder.api_password)">
            {{ "COPY_PASSWORD" | translate }}
        </a>
    </div>
</div>
<div class="form-group row" *ngIf="feeder.accessKey">
    <h5 class="col-12" translate>SSH_KEY</h5>
    <div class="col-12">
        <span *ngIf="feeder.accessKey.readOnly">{{ feeder.accessKey.name }}</span>
        <a *ngIf="!feeder.accessKey.readOnly && feeder.accessKey?.hasRsaPrivate" href="/api/access_keys/{{ feeder.accessKey.id }}/download">
            <fa-icon icon="download" size="sm"></fa-icon>
            {{ feeder.accessKey.name }}
        </a>
    </div>
</div>
<div class="form-group row" *ngIf="feeder.status && feeder.status.about && feeder.status.about.platform">
    <h5 class="col-12" translate>PLATFORM</h5>
    <div class="col-12">
        <span>{{ feeder.status.about.platform }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="feeder.status && feeder.status.source_ip">
    <h5 class="col-12" translate>SOURCE_IP</h5>
    <div class="col-12">
        <span>{{ feeder.status.source_ip }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="feeder.status && feeder.status.up_time_seconds">
    <h5 class="col-12" translate>UPTIME</h5>
    <div class="col-12">
        <span>{{ feeder.status.up_time_seconds | uptime }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="feeder.status && feeder.status.cpu">
    <h5 class="col-12" translate>CPU</h5>
    <div class="col-12">
        <span>{{ feeder.status.cpu / 100 | percent : "1.0-2" }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="feeder.status && feeder.status.ram">
    <h5 class="col-12" translate>MEMORY</h5>
    <div class="col-12">
        <span>{{ feeder.status.ram / 100 | percent : "1.0-2" }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="feeder.status && feeder.status.hostid">
    <h5 class="col-12" translate>HOST_ID</h5>
    <div class="col-12">
        <span>{{ feeder.status.hostid }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="feeder.status && feeder.status.activation_key_link">
    <h5 class="col-12" translate>ACTIVATION_KEY</h5>
    <div class="col-12">
        <span
            ><a [href]="feeder.status?.activation_key_link" target="_blank">License Details <fa-icon icon="external-link-alt" size="sm"></fa-icon></a
        ></span>
    </div>
</div>
<div class="form-group row" *ngIf="feeder.status && feeder.status.about && feeder.status.about.version">
    <h5 class="col-12" translate>VERSION</h5>
    <div class="col-12">
        <span>{{ feeder.status.about.version }}</span>
    </div>
</div>
<div class="form-group row" [ngClass]="{ 'd-none': feeder.state === 'pending' || !canEdit }">
    <h5 class="col-12" translate>OPEN</h5>
    <div class="col-12">
        <a href="{{ feeder.configure_link }}" target="_blank">
            <fa-icon icon="external-link-alt" size="sm"></fa-icon>
            {{ "LINK" | translate }}
        </a>
    </div>
</div>
<!-- Location -->
<app-details-location [location]="feeder.location"></app-details-location>
<div
    class="form-group row mt-2"
    *ngIf="
        !feeder.location ||
        !feeder.location?.address ||
        feeder.location?.address.latitude !== dataMarkerLatLng().lat ||
        feeder.location?.address.longitude !== dataMarkerLatLng().lng
    "
>
    <h5 class="col-12" translate>LAT/LNG</h5>
    <div class="col-12">
        <span title="{{ 'LAT' | translate }}: {{ dataMarkerLatLng().lat }}, {{ 'LNG' | translate }}: {{ dataMarkerLatLng().lng }}"
            >{{ "LAT" | translate }}: {{ dataMarkerLatLng().lat }}, {{ "LNG" | translate }}: {{ dataMarkerLatLng().lng }}</span
        >
    </div>
</div>
