import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { Source, Broadcaster } from "../../../../models/shared";
import { DeviceDetectorService } from "ngx-device-detector";
import { take } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { DataMarker } from "../../map";
import { UsersService } from "src/app/pages/account-management/users/users.service";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { ClipboardService } from "ngx-clipboard";
@Component({
    selector: "app-source-content",
    templateUrl: "./source-content.component.html"
})
export class SourceContentComponent implements OnInit, OnChanges {
    @Input() source: Source;
    @Input() canEdit: boolean;
    @Input() dataMarker: DataMarker;

    searchTerm: string;
    pwVisible = false;
    canVLC: boolean;
    targetBXsLoading = true;
    targetBroadcaster: Broadcaster;

    urls = Constants.urls;
    constants = Constants;

    primaryFailoverSources: Source[] = [];
    secondaryFailoverSources: Source[] = [];
    slateFailoverSources: Source[] = [];

    isAdmin: boolean;

    constructor(
        private deviceService: DeviceDetectorService,
        private broadcastersService: BroadcastersService,
        private cbs: ClipboardService,
        private userService: UsersService
    ) {
        this.canVLC = ["windows", "mac"].includes(this.deviceService.os.toLocaleLowerCase());
    }

    dataMarkerLatLng() {
        if (this.dataMarker) {
            const latlng = this.dataMarker.getLatLng();
            return { lat: latlng.lat, lng: latlng.lng };
        }
    }

    ngOnInit() {
        this.initFailoverSources();
        if (
            this.source.target_broadcaster_id &&
            this.source.broadcaster_cluster_id &&
            this.source.target_broadcaster_id !== -1 &&
            this.source.target_broadcaster_id !== -2 &&
            this.source.target_broadcaster_id !== -3 &&
            this.source.target_broadcaster_id !== -4
        ) {
            this.getTargetBroadcasters(this.source.broadcaster_cluster_id);
        }

        this.userService
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                this.isAdmin = !!user.is_admin;
            });
    }

    initFailoverSources() {
        this.primaryFailoverSources = this.source.failoverSources
            ? this.source.failoverSources.filter(fs => fs.priority > 1).map(fs => fs.source)
            : [];
        this.secondaryFailoverSources = this.source.failoverSources
            ? this.source.failoverSources.filter(fs => fs.priority === 1).map(fs => fs.source)
            : [];
        this.slateFailoverSources = this.source.failoverSources
            ? this.source.failoverSources.filter(fs => fs.priority === 0).map(fs => fs.source)
            : [];
    }

    async getTargetBroadcasters(id: number) {
        this.targetBXsLoading = true;
        const broadcasters = await this.broadcastersService.refreshBroadcasters(id, true).toPromise();
        this.targetBroadcaster = broadcasters.find(b => b.id === this.source.target_broadcaster_id);
        this.targetBXsLoading = false;
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.source) {
            if (changes.source.previousValue && changes.source.currentValue) {
                if (changes.source.previousValue.id !== changes.source.currentValue.id) {
                    this.initFailoverSources();
                    this.pwVisible = false;
                    if (
                        this.source.target_broadcaster_id &&
                        this.source.broadcaster_cluster_id &&
                        this.source.target_broadcaster_id !== -1 &&
                        this.source.target_broadcaster_id !== -2 &&
                        this.source.target_broadcaster_id !== -3 &&
                        this.source.target_broadcaster_id !== -4
                    ) {
                        this.getTargetBroadcasters(this.source.broadcaster_cluster_id);
                    }
                }
            }
        }
    }
}
