import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { DataMarker } from "../../map";
import { Broadcaster } from "../../../../models/shared";

@Component({
    selector: "app-broadcaster-content",
    templateUrl: "./broadcaster-content.component.html"
})
export class BroadcasterContentComponent implements OnChanges {
    @Input() broadcaster: Broadcaster;
    @Input() canEdit: boolean;
    @Input() dataMarker: DataMarker;
    pwVisible = false;

    hasGPU() {
        return (
            this.broadcaster.recentLoad &&
            (this.broadcaster.recentLoad.nvidia_utilization === 0 || this.broadcaster.recentLoad.nvidia_utilization > 0)
        );
    }

    dataMarkerLatLng() {
        if (this.dataMarker) {
            const latlng = this.dataMarker.getLatLng();
            return { lat: latlng.lat, lng: latlng.lng };
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.broadcaster) {
            if (changes.broadcaster.previousValue && changes.broadcaster.currentValue) {
                if (changes.broadcaster.previousValue.id !== changes.broadcaster.currentValue.id) {
                    this.pwVisible = false;
                }
            }
        }
    }

    get Math() {
        return Math;
    }
}
