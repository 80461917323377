<div class="map-legend">
    <h4>{{ "MAP_LEGEND" | translate }}</h4>
    <div class="d-block float-start me-2">
        <h5 *ngIf="!healthMode">{{ "STATUS" | translate }}</h5>
        <h5 *ngIf="healthMode">
            {{ "HEALTH" | translate }}&nbsp;<fa-icon icon="info-circle" [ngbTooltip]="HealthContent" triggers="hover click" [closeDelay]="500"></fa-icon>
            <ng-template #HealthContent>
                <p class="mb-0">
                    {{ "TOOLTIP.HEALTH_SCORE" | translate }}
                </p>
            </ng-template>
        </h5>
        <ul class="statuses">
            <li>
                <div class="status-block good"></div>
                <span class="align-top">&nbsp;&nbsp;-&nbsp;{{ "OK" | translate }}</span>
            </li>
            <li>
                <div class="status-block warning"></div>
                <span class="align-top">&nbsp;&nbsp;-&nbsp;{{ "WARNING" | translate }}</span>
            </li>
            <li>
                <div class="status-block error"></div>
                <span class="align-top">&nbsp;&nbsp;-&nbsp;{{ "ERROR" | translate }}</span>
            </li>
            <li>
                <div class="status-block disabled"></div>
                <span class="align-top">&nbsp;&nbsp;-&nbsp;{{ "DISABLED" | translate }}</span>
            </li>
        </ul>
    </div>
    <div class="d-block float-start me-2">
        <h5>{{ "MARKER" | translate }}</h5>
        <ul class="markers">
            <li>
                <div class="marker-block customMarker status-disabled">
                    <div><span>F</span></div>
                </div>
                <span class="align-middle">&nbsp;&nbsp;-&nbsp;&nbsp;{{ "FEEDER" | translate }}</span>
            </li>
            <li>
                <div class="marker-block customMarker status-disabled">
                    <div><span>R</span></div>
                </div>
                <span class="align-middle">&nbsp;&nbsp;-&nbsp;&nbsp;{{ "RECEIVER" | translate }}</span>
            </li>
            <li>
                <div class="marker-block customMarker status-disabled">
                    <div><span>B</span></div>
                </div>
                <span class="align-middle">&nbsp;&nbsp;-&nbsp;&nbsp;{{ "BROADCASTER" | translate }}</span>
            </li>
            <li>
                <div class="marker-block customMarker targetMarker status-disabled">
                    <div><span>T</span></div>
                </div>
                <span class="align-middle">&nbsp;&nbsp;-&nbsp;&nbsp;{{ "TARGET" | translate }}</span>
            </li>
            <li>
                <div class="marker-block customMarker sourceMarker status-disabled">
                    <div><span>S</span></div>
                </div>
                <span class="align-middle">&nbsp;&nbsp;-&nbsp;&nbsp;{{ "SOURCE" | translate }}</span>
            </li>
            <!--<li>
                <div class="marker-block customMarker sourceMarker status-disabled">
                    <div>
                        <div class="healthScore-container status-disabled"><span>S</span></div>
                    </div>
                </div>
                <span
                    [ngStyle]="{
                        'vertical-align': 'super'
                    }"
                    >&nbsp;&nbsp;-&nbsp;&nbsp;{{ "SOURCE" | translate }}</span
                >
            </li>-->
            <!--<li>
                <div class="marker-block customMarker sourceMarker status-good">
                    <div>
                        <div class="healthScore-container status-good"><span>88</span></div>
                    </div>
                </div>
                <span
                    [ngStyle]="{
                        'vertical-align': 'super'
                    }"
                    >&nbsp;&nbsp;-&nbsp;&nbsp;{{ "SOURCE_WITH_HEALTH_SCORE" | translate }}</span
                >
            </li>-->
        </ul>
    </div>
    <div class="d-block float-start me-2">
        <h5>{{ "MARKER_CLUSTER" | translate }}</h5>
        <div class="marker-clusters">
            <div class="leaflet-marker-icon marker-cluster marker-cluster-medium leaflet-zoom-animated leaflet-interactive">
                <div>
                    <span
                        ><span class="status-good">1</span>/<span class="status-warning">1</span>/<span class="status-bad">1</span>/<span
                            class="status-disabled"
                            >1</span
                        ></span
                    >
                </div>
            </div>
            <span class="float-end mt-2">&nbsp;&nbsp;-&nbsp;&nbsp;{{ "4_MARKERS" | translate }}</span>
        </div>
    </div>
    <div class="d-block float-start">
        <h5 class="mt-2 mb-0">{{ "CONNECTION" | translate }}</h5>
        <ul class="connections mb-0">
            <li>
                <svg class="leaflet-zoom-animated" height="24" pointer-events="none" viewBox="-1 -8 32 16" width="40">
                    <g>
                        <path
                            d="M0 0L30 0"
                            fill="none"
                            stroke="#2e7d32"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-opacity="0.9"
                            stroke-width="5"
                        ></path>
                        <path
                            class="leaflet-ant-path ant-path-1600097474932"
                            d="M0 0L30 0"
                            data-animated="true"
                            fill="none"
                            stroke="#4CAF50"
                            stroke-dasharray="9,18"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-opacity="0.9"
                            stroke-width="5"
                            style="animation-duration: 3s"
                        ></path>
                    </g>
                </svg>
                <span>&nbsp;&nbsp;-&nbsp;&nbsp;{{ "ACTIVE" | translate }}</span>
            </li>
            <li *ngIf="healthMode">
                <svg class="leaflet-zoom-animated" height="24" pointer-events="none" viewBox="-1 -8 32 16" width="40">
                    <g>
                        <path
                            d="M0 0L30 0"
                            fill="none"
                            stroke="#ef6c00"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-opacity="0.9"
                            stroke-width="5"
                        ></path>
                        <path
                            class="leaflet-ant-path ant-path-1600097474932"
                            d="M0 0L30 0"
                            data-animated="true"
                            fill="none"
                            stroke="#FF9800"
                            stroke-dasharray="9,18"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-opacity="0.9"
                            stroke-width="5"
                            style="animation-duration: 6s"
                        ></path>
                    </g>
                </svg>
                <span>&nbsp;&nbsp;-&nbsp;&nbsp;{{ "WARNING" | translate }}</span>
            </li>
            <li *ngIf="healthMode">
                <svg class="leaflet-zoom-animated" height="24" pointer-events="none" viewBox="-1 -8 32 16" width="40">
                    <g>
                        <path
                            d="M0 0L30 0"
                            fill="none"
                            stroke="#d32f2f"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-opacity="0.9"
                            stroke-width="5"
                        ></path>
                        <path
                            class="leaflet-ant-path ant-path-1600097474932"
                            d="M0 0L30 0"
                            data-animated="true"
                            fill="none"
                            stroke="#E57373"
                            stroke-dasharray="9,18"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-opacity="0.9"
                            stroke-width="5"
                            style="animation-duration: 9s"
                        ></path>
                    </g>
                </svg>
                <span>&nbsp;&nbsp;-&nbsp;&nbsp;{{ "ERROR" | translate }}</span>
            </li>
            <li>
                <svg class="leaflet-zoom-animated" height="24" pointer-events="none" viewBox="-1 -8 32 16" width="40">
                    <g>
                        <path
                            d="M0 0L30 0"
                            fill="none"
                            stroke="#757575"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-opacity="0.9"
                            stroke-width="5"
                        ></path>
                        <path
                            class="leaflet-ant-path ant-path-1600097474932"
                            d="M0 0L30 0"
                            data-animated="true"
                            fill="none"
                            stroke="#e0e0e0"
                            stroke-dasharray="9,18"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-opacity="0.9"
                            stroke-width="5"
                            style="animation-duration: 9s"
                        ></path>
                    </g>
                </svg>
                <span>&nbsp;&nbsp;-&nbsp;&nbsp;{{ "INACTIVE" | translate }}</span>
            </li>
        </ul>
    </div>
    <small class="d-block float-start mb-1">*&nbsp;{{ "SELECT_AND_HOLD_CTRL_KEY_TO_DRAG_MARKERS_TO_NEW_LOCATIONS_ON_THE_MAP" | translate }}</small>
</div>
