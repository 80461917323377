/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as L from "leaflet";
import "leaflet.markercluster";

// @ts-ignore
export class ParentClusterGroup extends L.MarkerClusterGroup {
    // @ts-ignore
    constructor(options?: L.MarkerClusterGroupOptions) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        super(options);
    }
}
