import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription, interval } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";

import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { Map } from "../map";
import { MapService } from "../map.service";
import { FilterPipe } from "src/app/pipes/filter.pipe";
import { TitleService } from "../../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { KeyMap } from "src/app/models/shared";
import { TitleCasePipe } from "@angular/common";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";

@Component({
    selector: "app-map-list",
    templateUrl: "./map-list.component.html",
    providers: [TitleCasePipe]
})
export class MapListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;

    maps: Map[];
    selectedRows: Array<Map> = [];

    isAdmin: boolean;
    urls = Constants.urls;
    constants = Constants;

    private mapsSubscription: Subscription;
    private mapsRefreshSubscription: Subscription;

    private mapsBS$ = new BehaviorSubject<Map[]>([]);

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 200,
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Map>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: KeyMap<Map>): string => row.name,
            textValue: (row: KeyMap<Map>): string => row.name
        },
        {
            header: this.translate.instant("TYPE"),
            columnDef: "type",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Map>>(
                row => this.translate.instant(row.type.toUpperCase()),
                row => this.translate.instant(row.type.toUpperCase()),
                () => true
            ),
            sortBy: (row: KeyMap<Map>): string => row.type,
            textValue: (row: KeyMap<Map>): string => row.type
        },
        {
            header: this.translate.instant("REGION"),
            columnDef: "region",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Map>>(
                row => Constants.mapRegions.filter(r => r.value === row.region)[0].name,
                row => Constants.mapRegions.filter(r => r.value === row.region)[0].name,
                () => true
            ),
            sortBy: (row: KeyMap<Map>): string => Constants.mapRegions.filter(r => r.value === row.region)[0].name,
            textValue: (row: KeyMap<Map>): string => Constants.mapRegions.filter(r => r.value === row.region)[0].name
        },
        {
            header: this.translate.instant("STYLE"),
            columnDef: "baseMap",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Map>>(
                row => this.titlecasePipe.transform(row.baseMap),
                row => this.titlecasePipe.transform(row.baseMap),
                () => true
            ),
            sortBy: (row: KeyMap<Map>): string => row.baseMap,
            textValue: (row: KeyMap<Map>): string => row.baseMap
        },
        {
            header: this.translate.instant("#_OF_LAYERS"),
            columnDef: "number_of_layers",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Map>>(
                row => (row._frontData.numberOfLayers ? row._frontData.numberOfLayers.toString() : "0"),
                row => (row._frontData.numberOfLayers ? row._frontData.numberOfLayers.toString() : "0"),
                () => true
            ),
            sortBy: (row: KeyMap<Map>): number => (row._frontData.numberOfLayers ? row._frontData.numberOfLayers : 0),
            textValue: (row: KeyMap<Map>): number => (row._frontData.numberOfLayers ? row._frontData.numberOfLayers : 0)
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 60,
            visible: true,
            stickyToLast: true,
            align: "right",
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<Map, Promise<void>>({
                canEditCallBack: () => true,
                canDeleteCallBack: () => true,
                canCloneCallBack: () => true,
                editRef: row => this.edit(row),
                deleteRef: row => this.delete(row),
                cloneRef: row => this.clone(row)
            })
        }
    ];

    constructor(
        private router: Router,
        private fp: FilterPipe,
        public sharedService: SharedService,
        private modalService: ModalService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private ms: MapService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe
    ) {
        // Set Title
        this.titleService.setTitle(this.translate.instant("MAPS"));
    }

    ngOnInit() {
        this.loading = true;

        // isAdmin
        this.userService.isAdmin.pipe(take(1)).subscribe(bool => {
            this.isAdmin = bool;
        });

        this.mapsSubscription = this.ms.maps.subscribe(maps => {
            this.maps = maps;
            if (this.maps) {
                this.prepTableData();
                this.loading = false;
            }
        });

        // Start Auto Refresh
        this.startMapsRefresh();
    }

    ngOnDestroy() {
        this.mapsSubscription.unsubscribe();
        this.stopMapsRefresh();
    }

    startMapsRefresh() {
        this.mapsRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopMapsRefresh() {
        this.mapsRefreshSubscription.unsubscribe();
    }

    selectRow = (map: Map) => {
        this.router.navigate([Constants.urls.maps, map.id]);
    };

    async refresh() {
        this.refreshing = true;
        const maps = this.ms.refreshMaps(true).toPromise();
        await Promise.all([maps]);
        this.refreshing = false;
    }

    async multiAction(action: string, func: (map: Map) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "MAP", this.selectedRows, func);
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple maps");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (map: Map) => this.ms.deleteMap(map));
    }

    async multiEdit() {
        await this.modalService.editMultiple("MAP", this.selectedRows, async (map: Map, model) => {
            return this.ms.updateMap(map.id, model);
        });
    }

    edit(map: Map): void {
        this.router.navigate([Constants.urls.maps, map.id, "edit"]);
    }

    clone(map: Map): void {
        this.router.navigate([Constants.urls.maps, map.id, "clone"]);
    }

    async delete(map: Map) {
        await this.modalService.confirm(
            "DELETE",
            "MAP",
            async () => {
                const id = map.id;
                const result = await this.ms.deleteMap(map);
                if (result) {
                    this.mixpanelService.sendEvent("delete map", { id });
                } else return false;
            },
            map.name
        );
    }

    get maps$() {
        return this.mapsBS$.asObservable();
    }

    private prepTableData() {
        if (this.maps) {
            this.mapsBS$.next([...this.maps]);
        }
    }
}
