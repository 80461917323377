import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { DataMarker } from "../../map";
import { Receiver } from "../../../zecs/zecs/zec";

@Component({
    selector: "app-receiver-content",
    templateUrl: "./receiver-content.component.html"
})
export class ReceiverContentComponent implements OnChanges {
    @Input() receiver: Receiver;
    @Input() canEdit: boolean;
    @Input() dataMarker: DataMarker;
    pwVisible = false;

    constructor(private cbs: ClipboardService) {}

    dataMarkerLatLng() {
        if (this.dataMarker) {
            const latlng = this.dataMarker.getLatLng();
            return { lat: latlng.lat, lng: latlng.lng };
        }
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.receiver) {
            if (changes.receiver.previousValue && changes.receiver.currentValue) {
                if (changes.receiver.previousValue.id !== changes.receiver.currentValue.id) {
                    this.pwVisible = false;
                }
            }
        }
    }
}
