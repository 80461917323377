/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as L from "leaflet";
import "leaflet.markercluster";
import { LayerInfo } from "./map";

// @ts-ignore
export class ChildClusterGroup extends L.MarkerClusterGroup.SubGroup {
    data: LayerInfo;

    constructor(parentGroup?: L.LayerGroup, layers?: L.Layer[], data?: LayerInfo) {
        super(parentGroup, layers);
        this.setData(data);
    }

    getData() {
        return this.data;
    }

    setData(data: LayerInfo) {
        this.data = data;
    }
}
